import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject, Subscription } from 'rxjs';
import { KeysService } from './encryption/keys.service';
import { AuthService } from '@eva-core/auth.service';
import { FirestoreService } from '@eva-services/firestore/firestore.service';
import { environment } from '@environments/environment';
import { auditTime, filter, first, flatMap, map, take } from 'rxjs/operators';
import { FlowThroughGroupConfig } from '@eva-model/process/flowThroughGroups';
import { Group } from '@eva-model/group';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { AngularFirePerformance, trace } from '@angular/fire/compat/performance';
// import { WorkflowService } from "@eva-services/workflow/workflow.service";
import { ActiveWorkflowsInGroups } from "@eva-model/active-workflows-in-groups";
// import { sortBy } from 'lodash';

// import * as firebase from 'firebase/compat/app';
// import 'firebase/performance';
// const perffb = firebase.performance();
@Injectable({
  providedIn: 'root'
})
export class EvaGlobalService implements OnDestroy {

  public userId: string;                                        // unique user ID
  public userPreferredName: string;                             // preferred name specified by user
  public userEmail: string;                                     // user email ID
  public userGroups$: Observable<Group[]>;                      // Observable for all the user groups
  public userPublicKey: string;                                 // current user public key
  public userPhotoUrl: string;                                  // URL of the user photo
  public userGroups: Group[];                                   // array of current user groups
  public userGroupsPublicKeys: string[];                        // array of user group public keys
  public userGroupsPublicKeys$: BehaviorSubject<string[]> = new BehaviorSubject(null); // array of user group public keys
  public publicGroups: Group[];                                 // array of user groups and global groups
  public publicGroupKeys: string[];                             // array of user group public keys and global group keys
  public userNotificationExceptedGroups$: Observable<any[]>;    // Observable for all user notification expected groups
  public userNotificationExceptedGroups: string[];              // array of all user notification expected groups

  public processFlowThroughGroupConfig: FlowThroughGroupConfig; // process flow through group config db document
  public processFlowThroughGroup: Group;                        // refined process flow through group object

  public processGroupsCustomMessage: any[];                     // process groups custom message db document

  evaGlobalSubscription = new Subscription();                   // Subscription holder for observables in the service

  // activeWorkflowInGroups: any = [];                             // Array of active workflows in groups
  // activeWorkflowInGroups$ = new Subject<any[]>();
  // activeWorkflowInGroupsFetched = false;

  // Subject that emits the current workflow groups that have been fetched
  fetchActiveWorkflowsInGroupsNotifier = new ReplaySubject<ActiveWorkflowsInGroups>(1);
  // private fetchActiveWorkflowsInGroupsNotifierCount = 0;        // used to track whether more workflows should be expected
  // private uniqueGroupSet: Set<string> = new Set();              // used to track unique group public key and subgroups combinations

  // Observable any sources
  private userSource = new BehaviorSubject<any>(true);          // BehaviorSubject for user source change
  private userGroupsSource = new BehaviorSubject<any>(false);    // BehaviorSubject for user groups source change
  private userNotifyExceptedGroupsSource
    = new BehaviorSubject<any>(true);                           // BehaviorSubject for user notification excepted groups source change

  // Observable any streams
  public userChange$ = this.userSource.asObservable();          // Observable for user source change
  public userGroupsChange$
    = this.userGroupsSource.asObservable();                     // Observable for user groups source change
  public userNotifyExceptedGroupsChange$
    = this.userNotifyExceptedGroupsSource.asObservable();       // Observable for user notification excepted groups source change

  // Detected device info such as "browser", "os", "device", "userAgent", and "os version"
  evaDeviceInfo: DeviceInfo;

  constructor(
    private authService: AuthService,
    private keysService: KeysService,
    private _firestoreService: FirestoreService,
    private deviceDetectorService: DeviceDetectorService,
    private perf: AngularFirePerformance) {
    // private workflowService: WorkflowService) {

      // collect device info such as "browser", "os", "device", "userAgent", and "os version"
      this.evaDeviceInfo = this.deviceDetectorService.getDeviceInfo();
      this.init();
  }

  /**
   * This function initializes the eva config and current user data.
   */
  public init(): void {
    //#region EVA config related observables
    this.processFlowThroughGroupInConfig();
    this.processGroupDestinationCustomMessage();
    //#endregion

    this.authService.user
      .subscribe(
        async (user: any) => {
          try {
            if (user) {
              this.evaGlobalSubscription = new Subscription();
              // store currently logged in user data
              this.userId = user.uid;
              this.userPreferredName = user.preferredName;
              this.userEmail = user.email;
              const currentUserPublicKey = await this.keysService.getUserPublicKey();
              this.userPublicKey = (currentUserPublicKey) ? currentUserPublicKey.toString() : null;
              this.userPhotoUrl = user.photoURL;

              // announce user data change
              this.announceUserChange(true);
              // fetch user groups
              this.getUserGroups();

            } else {

              this.userId = null;
              this.userPublicKey = null;
              // if user doesn't exist, clear all the data for user groups
              this.evaGlobalUserGroupCleanUp();
              // announce user data change
              this.announceUserChange(false);
              this.evaGlobalSubscription.unsubscribe();

            }
          } catch (err) {
            // this.activeWorkflowInGroups$.next([]);
            // this.activeWorkflowInGroupsFetched = true;
            console.log(err);
          }
        },
        (err) => console.log(err)
    );

  }

  /**
   * This function clears all the user group arrays if user logs out or doesn't exist
   */
  evaGlobalUserGroupCleanUp(): void {
    if (this.userGroups) this.userGroups.length = 0;
    else this.userGroups = [];

    if (this.userGroupsPublicKeys) this.userGroupsPublicKeys.length = 0;
    else this.userGroupsPublicKeys = [];

    if (this.publicGroupKeys) this.publicGroupKeys.length = 0;
    else this.publicGroupKeys = [];

    if (this.publicGroups) this.publicGroups.length = 0;
    else this.publicGroups = [];
  }

  //#region Service message commands to emit a change
  /**
   * This function announces change for user source
   *
   * @param message whether new data assigned or not
   */
  announceUserChange(message: boolean = true): void {
    this.userSource.next(message);
  }

  /**
   * This function announces change for user groups source
   *
   * @param message whether new data assigned or not
   */
  announceUserGroupsChange(message: boolean = true): void {
    this.userGroupsSource.next(message);
  }

  /**
   * This function announces change for user notification excepted groups
   *
   * @param message whether new data assigned or not
   */
  announceUserNotifyExceptedGroupsChange(message: boolean = true): void {
    this.userNotifyExceptedGroupsSource.next(message);
  }
  //#endregion

  /**
   * This function returns the group name from group public key
   *
   * @param groupPublicKey public key of the group for which the name is being returned
   */
  public getGroupNameByPublicKey(groupPublicKey: string): string {
    if (this.userGroups && Array.isArray(this.userGroups) && this.userGroups.length > 0) {
      const groupIndex = this.userGroups.map(group => group.groupPublicKey).indexOf(groupPublicKey);
      if (groupIndex !== -1) {
        return this.userGroups[groupIndex].groupName;
      } else {
        if (groupPublicKey === environment.atbGlobalGroup) {
          return 'ATB Global';
        } else if (groupPublicKey === environment.evaGlobalGroup) {
          return 'EVA Global';
        } else if (groupPublicKey === '0494d22d1569f4541550557ca6ed12a155075502344f1411cc0b3d34a3be3e32e5e2314946af0bc45313c44f11455cf9a4cf005a9054a32daca6ccf08054b258ab') {
          return 'ATB Knowledge';
        } else {
          return 'ATB';
        }
      }
    } else {
      if (groupPublicKey === environment.atbGlobalGroup) {
        return 'ATB Global';
      }
      if (groupPublicKey === environment.evaGlobalGroup) {
        return 'EVA Global';
      }
      if (groupPublicKey === '0494d22d1569f4541550557ca6ed12a155075502344f1411cc0b3d34a3be3e32e5e2314946af0bc45313c44f11455cf9a4cf005a9054a32daca6ccf08054b258ab') {
        return 'ATB Knowledge';
      }
      return 'ATB';
    }
  }

  /**
   * This function fetches user groups
   */
  public getUserGroups(): void {
    this.userGroups$ = this._firestoreService.col<Group>(
      'GroupSigningKeys',
      reference => reference.where(`signingMembership.${this.userPublicKey}`, '==', true)
    ).valueChanges().pipe(
      trace('eva-global-getUserGroups'),
      auditTime(500)
    );

    this.evaGlobalSubscription.add(
      this.userGroups$
        .subscribe(
          async (groups) => {
            this.userGroupsPublicKeys = [];
            // clear arrays before adding user groups
            this.evaGlobalUserGroupCleanUp();
            const userEmail = await this.authService.getUserEmail();

            if (userEmail.endsWith('@atb.com')) {
              //#region atbGlobalGroup
              this.publicGroupKeys.push(environment.atbGlobalGroup);
              this.publicGroups.push({
                groupName: 'ATB Global',
                groupPublicKey: environment.atbGlobalGroup,
                // eslint-disable-next-line max-len
                groupSigningMembership: ['041afcc9065ddd9e5f0a887cdb09374e7421c1023411990d12dd0d9ae959c5886beb4be745b75188dbb815636f3c87689320b08cf418232918adbaeefbcd4905a6'],
                groupType: 'Tm9ybWFsR3JvdXBSdWxlcw==',
                signingMembership: {
                  // eslint-disable-next-line max-len
                  ['041afcc9065ddd9e5f0a887cdb09374e7421c1023411990d12dd0d9ae959c5886beb4be745b75188dbb815636f3c87689320b08cf418232918adbaeefbcd4905a6']: true
                }
              });
              //#endregion
            }

            //#region evaGlobalGroup
            this.publicGroupKeys.push(environment.evaGlobalGroup);
            this.publicGroups.push({
              groupName: 'EVA Global',
              groupPublicKey: environment.evaGlobalGroup,
              // eslint-disable-next-line max-len
              groupSigningMembership: ['041afcc9065ddd9e5f0a887cdb09374e7421c1023411990d12dd0d9ae959c5886beb4be745b75188dbb815636f3c87689320b08cf418232918adbaeefbcd4905a6'],
              groupType: 'Tm9ybWFsR3JvdXBSdWxlcw==',
              signingMembership: {
                // eslint-disable-next-line max-len
                ['041afcc9065ddd9e5f0a887cdb09374e7421c1023411990d12dd0d9ae959c5886beb4be745b75188dbb815636f3c87689320b08cf418232918adbaeefbcd4905a6']: true
              }
            });
            //#endregion

            groups.forEach(group => {
              this.userGroups.push(Object.assign({}, group));
              this.userGroupsPublicKeys.push(group.groupPublicKey);
            });

            this.userGroupsPublicKeys$.next(this.userGroupsPublicKeys);

            this.announceUserGroupsChange(true);
            // this.getActiveWorkflows();
          },
          (error) => {
            console.log(error);
            // this.activeWorkflowInGroups$.next([]);
            // this.activeWorkflowInGroupsFetched = true;
          }
        )
    );

    this.userProcessNotificationExceptedGroups();
  }

  /**
   * Adds a subscription on firestore document EVAConfig/ProcessFlowThroughGroup.
   * When updated, this gets the value of the Group Signing Key from the value of the process Flow Through Group Public Key and\
   * stores its value in this.processFlowThroughGroup
   */
  private processFlowThroughGroupInConfig(): void {
    this.evaGlobalSubscription.add(
      this._firestoreService.doc$<FlowThroughGroupConfig>('EVAConfig/ProcessFlowThroughGroup')
        .pipe(
          trace('eva-global-processFlowThroughGroupInConfig'),
          map((processFlowThroughGroupObject: FlowThroughGroupConfig) => {
            this.processFlowThroughGroupConfig = processFlowThroughGroupObject;
            if (!processFlowThroughGroupObject) {
              throw Error('processFlowThroughGroupObject empty');
            }
            return processFlowThroughGroupObject.groupPublicKey;
          }),
          flatMap(processFlowThroughGroupPublicKey =>
            this._firestoreService.col<Group>(
              'GroupSigningKeys',
              ref => ref.where(`groupPublicKey`, '==', processFlowThroughGroupPublicKey))
              .valueChanges().pipe(
                trace('eva-global-processFlowThroughGroupInConfig-interactions')
              )
          ),
          map(processFlowThroughGroups => processFlowThroughGroups[0])
        )
        .subscribe(
          (processFlowThroughGroup) => {
            this.processFlowThroughGroup = processFlowThroughGroup;
          },
          (err) => { console.log(err); }
        ));
  }

  /**
   * Fetch "process flow through group" related data
   * (To force fetch in case if the data is not ready yet)
   */
  public async processFlowThroughGroupInConfigForceFetch(): Promise<void> {
    // const trace = this.perf.trace()


    // const trace = perffb.trace('evaglobal-processFlowThroughGroupInConfigForceFetch');
    // trace.start();

    try {

      // Fetch the Process Flow Through Group data from EVAConfig collection
      const processFlowThroughGroupConfig: any =
        await this._firestoreService.doc$('EVAConfig/ProcessFlowThroughGroup').pipe(
          trace('eva-global-processFlowThroughGroupInConfigForceFetch'),
          first(),
          ).toPromise();
        // trace.putAttribute('groupconfig-eva', this.userEmail);

      if ( !(processFlowThroughGroupConfig || processFlowThroughGroupConfig.groupPublicKey) ) {
        // trace.putAttribute('no-flow-through-group', this.userEmail);
        // trace.stop();
        return;
      }

      //#region Assign the fetched object to the corresponding property member of this service to utilize whenever service is in use
      this.processFlowThroughGroupConfig = {
        groupPublicKey: processFlowThroughGroupConfig.groupPublicKey,
        permanentMembersPublicKeys: processFlowThroughGroupConfig.permanentMembersPublicKeys
      };
      //#endregion

      const processFlowThroughGroupPublicKey = processFlowThroughGroupConfig.groupPublicKey;

      // Get the Process Flow Through Group members and related properties
      const prcsFTGrps =
        await this._firestoreService.col<Group>(
          'GroupSigningKeys',
          ref => ref.where(`groupPublicKey`, '==', processFlowThroughGroupPublicKey))
          .valueChanges().pipe(
            trace('eva-global-processFlowThroughGroupInConfigForceFetch-interactions'),
            first()
          ).toPromise();
      // trace.putAttribute('groupconfig-interactions', this.userEmail);

      this.processFlowThroughGroup = prcsFTGrps[0];

    } catch ( err ) {

      // Log and throw the error to calling method
      console.log( 'Force fetching for process FlowThrough Group from Config failed' );
      // trace.putAttribute('errorCode', err.code);
      // trace.stop();
      throw err;
    }

  }

  private userProcessNotificationExceptedGroups(): void {
    this.userNotificationExceptedGroups$ =
      this._firestoreService.doc$(`EVAConfig/UserProcessNotificationExceptedGroups/users/${this.userId}`);

    this.evaGlobalSubscription.add(
      this.userNotificationExceptedGroups$
        .pipe(
          trace('eva-global-userProcessNotificationExceptedGroups')
        )
        .subscribe((userException: any) => {
          if (this.userNotificationExceptedGroups) this.userNotificationExceptedGroups.length = 0;
          else this.userNotificationExceptedGroups = [];

          if (userException && userException.NotificationExceptedGroups &&
            Array.isArray(userException.NotificationExceptedGroups) &&
            userException.NotificationExceptedGroups.length > 0) {
            userException.NotificationExceptedGroups.forEach(groupPublicKey => {
              this.userNotificationExceptedGroups.push(groupPublicKey);
            });
          }
          this.announceUserNotifyExceptedGroupsChange();
        }, (error) => { console.log(error); }));
  }

  /**
   * This function fetches process group destination custom message
   */
  private processGroupDestinationCustomMessage(): void {
    this.evaGlobalSubscription.add(
      this._firestoreService.col$('EVAConfig/ProcessGroupDestinationCustomMessage/groups')
        .pipe(
          trace('eva-global-processGroupDestinationCustomMessage')
        )
        .subscribe(
          (groupsCustomMessage) => {
            this.processGroupsCustomMessage = groupsCustomMessage;
          },
          (error) => { console.log(error); }
        ));
  }


  ngOnDestroy() {
    if (this.evaGlobalSubscription) {
      this.evaGlobalSubscription.unsubscribe();
    }
  }

  // /**
  //  * This function fetches user active workflows for all groups
  //  */
  // public getActiveWorkflows() {
  //   // if this array already has contents, empty it for updated values to be added below
  //   // if (this.activeWorkflowInGroups.length > 0) {
  //   //   this.activeWorkflowInGroups = [];
  //   // }

  //   const allGroups: string [] = [];
  //   [this.publicGroupKeys, this.userGroupsPublicKeys].forEach(groupKeyArray => {
  //     groupKeyArray.forEach(groupPublicKey => {
  //       allGroups.push(groupPublicKey);
  //     });
  //   });

  //   if (allGroups.length === 0) {
  //     this.activeWorkflowInGroups$.next([]);
  //     this.activeWorkflowInGroupsFetched = true;
  //   }

  //   allGroups.forEach(groupPublicKey => {
  //     ++this.fetchActiveWorkflowsInGroupsNotifierCount;
  //     this.evaGlobalSubscription.add(
  //       this.getGroupPublicKey(groupPublicKey)
  //     );
  //   });
  // }

  // /**
  //  * Get a Subscription that retrieves active workflows for a given groupKey
  //  * @param groupPublicKey
  //  */
  // private getGroupPublicKey(groupPublicKey: string): Subscription {
  //   return this.workflowService.fetchActiveWorkflowsByGroup(groupPublicKey).pipe(
  //     take(1),
  //     filter(workflows => {
  //       return workflows.length > 0;
  //     })
  //   ).subscribe(
  //     (workflows) => {
  //       const allWorkflowsInGroup = {};
  //       workflows.forEach(workflow => {
  //         const undefinedString = 'undefined';
  //         const subGroup = workflow.subGroup ? workflow.subGroup : undefinedString;
  //         if (allWorkflowsInGroup[subGroup]) {
  //           allWorkflowsInGroup[subGroup].workflowArray.push(workflow);
  //         } else {
  //           allWorkflowsInGroup[subGroup] = {
  //             workflowArray: [workflow],
  //             workflowPublicKey: workflow.groupPublicKey,
  //             workflowSubGroup: subGroup === undefinedString ? '' : subGroup
  //           };
  //         }
  //       });
  //       Object.keys(allWorkflowsInGroup).forEach(subGroup => {
  //         if (!this.uniqueGroupSet.has(groupPublicKey + subGroup)) {
  //           // Only add groupPublicKey and subgroups that have not already been added
  //           this.uniqueGroupSet.add(groupPublicKey + subGroup);      // Add to prevent duplicates from being added
  //           this.activeWorkflowInGroups.push(this.sortWorkflows(allWorkflowsInGroup[subGroup]));
  //         }
  //       });

  //       this.activeWorkflowInGroups$.next(this.activeWorkflowInGroups ? this.activeWorkflowInGroups : []);
  //       this.activeWorkflowInGroupsFetched = true;
  //     },
  //     (error) => {
  //       console.log(error);
  //       this.activeWorkflowInGroups$.next(this.activeWorkflowInGroups);
  //       this.activeWorkflowInGroupsFetched = true;
  //     },
  //     () => {
  //       --this.fetchActiveWorkflowsInGroupsNotifierCount;
  //       this.fetchActiveWorkflowsInGroupsNotifier.next({
  //          activeWorkflowInGroups: this.activeWorkflowInGroups,
  //          isNotComplete: this.fetchActiveWorkflowsInGroupsNotifierCount > 0
  //        });
  //     });
  // }

  // /**
  //  * Sort the workflow array associated with this sub group; sort by workflow name to lower case.
  //  * @param subGroupWithWorkflows
  //  */
  // private sortWorkflows(subGroupWithWorkflows) {
  //   const allWorkflowsInGroupElement = subGroupWithWorkflows;
  //   if (allWorkflowsInGroupElement['workflowArray'].length > 1) { // only sort if there is more than 1 workflow
  //     allWorkflowsInGroupElement['workflowArray'] =
  //       sortBy(allWorkflowsInGroupElement['workflowArray'], [process => process['name'].toLowerCase()]);
  //   }
  //   return allWorkflowsInGroupElement;
  // }
}
