import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from '@eva-core/auth.service';
import { switchMap, take, tap } from 'rxjs/operators';
import { EvaChatEventTypes, EVAHeartQuery, EVAQueryResponse, EvaChatDirection,
  EvaCustomSignedIncomingChatRequest,
  EVAHeartQueryRequest,
  EvaHeartFilterResponseType,
  ChatEntityAuthor,
  ChatEntityType} from '@eva-model/chat/chat';
import { ChatService } from '@eva-services/chat/chat.service';
import { AngularFirePerformance, trace } from '@angular/fire/compat/performance';
import { Observable, throwError } from 'rxjs';
import {decode} from 'html-entities';

@Injectable()
export class EvaHeartService {

  constructor(
    private http: HttpClient,
    private afAuth: AuthService,
    private chatService: ChatService,
    private perf: AngularFirePerformance
  ) { }

  /**
   * This sends a query to the EVA heart API's and tries to obtain a response.
   * @param query the query that is being sent to match
   * TODO: This doesn't appear to have been completed or may have been removed. Might be in eva heart as part of
   * Might be part of 1840 and not marked here.
   * @param queryObject marker to force a dynamic interaction followup.
   * TODO: This doesn't appear to have been completed or may have been removed. Might be in eva heart as part of
   * Might be part of 1840 and not marked here.
   * @param event The event type to include.
   */
  // async send(query?: string, queryObject?: any, event?: EvaChatEventTypes): Promise<EVAQueryResponse> {
  async send(query: EVAHeartQuery): Promise<EVAQueryResponse> {
    if (!query.query) {
      return Promise.reject('no query or query object provided.');
    }

    this.notifyHeartRequestState(true);
    const url = environment.endPoints.EVA_HEART.url + environment.endPoints.EVA_HEART.query;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    try {
      const user = await this.afAuth.user.pipe(take(1)).toPromise();

      const evaHeartQuery: EVAHeartQueryRequest = {
        ...query,
        email: user.email,
        incomingGroupRequests: this.getUsersKnowledgeGroups()
      };

      // add in additional data if it exists.
      evaHeartQuery.direction = (query.direction) ? EvaChatDirection.DYNAMIC_INTERACTION_FOLLOWUP : EvaChatDirection.UNKNOWN;
      evaHeartQuery.event = (query.event) ? query.event : null;

      // should probably be in user service, but for no just testing
      if (localStorage.getItem('heartQueryFilter')) {
        evaHeartQuery.filter = [EvaHeartFilterResponseType.KNOWLEDGE];
      }

      // XSS - decode potential characters purely for sending to endpoint, once recieved, endpoint re-encodes html characters
      // re-encoded encoded strings leads to being unable to decode it.
      evaHeartQuery.query = decode(evaHeartQuery.query)

      const evaQueryResponse = await this.http.post<EVAQueryResponse>(url, evaHeartQuery, httpOptions).pipe(
        trace('eva-heart-send'),
        take(1)
      ).toPromise();
      this.notifyHeartRequestState(false);
      return evaQueryResponse;
    } catch (err) {
      console.log('Error in chat service: ', err);
      this.chatService.newChatEntity({
        author: ChatEntityAuthor.EVA,
        type: ChatEntityType.Text,
        text: `Hmm... something went wrong. Can you try again?`
      });
      this.notifyHeartRequestState(false);
      return Promise.reject(err);
    }
  }

  sendRequest(query: EVAHeartQuery): Observable<EVAQueryResponse> {
    if (!query.query) {
      throw throwError('no query or query object provided.');
    }
    
    const url = `${environment.endPoints.EVA_HEART.url}${environment.endPoints.EVA_HEART.query}`;

    this.notifyHeartRequestState(true);

    return this.afAuth.user.pipe(
      take(1),
      switchMap((user) => {
        const request: EVAHeartQueryRequest = {
          ...query,
          email: user.email,
          incomingGroupRequests: this.getUsersKnowledgeGroups()
        };

        // add in additional data if it exists.
        request.direction = (query.direction) ? EvaChatDirection.DYNAMIC_INTERACTION_FOLLOWUP : EvaChatDirection.UNKNOWN;
        request.event = (query.event) ? query.event : null;

        // should probably be in user service, or moved from here.
        if (localStorage.getItem('heartQueryFilter')) {
          request.filter = [EvaHeartFilterResponseType.KNOWLEDGE];
        }

        return this.http.post<EVAQueryResponse>(url, request, {headers: new HttpHeaders({'Content-Type': 'application/json'})}).pipe(
          tap(() => this.notifyHeartRequestState(false))
        )
      })
    )
  }

  /**
   * This function gets the users knowledge groups and put together a list of EVA custom signed items
   * TODO: Move this to a knowledge-group service.
   */
  getUsersKnowledgeGroups(): EvaCustomSignedIncomingChatRequest[] {
    // setup the main part of this.
    const signedIncomingChatRequest: EvaCustomSignedIncomingChatRequest[] = [];
    const testKG: EvaCustomSignedIncomingChatRequest = {
      groupData: {
        // eslint-disable-next-line max-len
        publicKey: '046cf73ed830cdb3e01e8a27ba569e9854e6bc67d0b6830f38c9c7260e3a665069ec7a2a18621dd076206372e39d8aca4ff4ea492a04e1b383f26cd5cac7eae9be',
        version: '100',
        timestamp: Date.now()
      },
      signature: ''
    };

    // add the items to the group request:
    signedIncomingChatRequest.push(testKG); // add thee items to the group requests.
    return signedIncomingChatRequest;
  }
  /**
   * Pass boolean to chatInProgress. This is connected to the chat ui and will show an indefinite progress bar.
   * Can pass boolean to trigger the bar to hide/show.
   */
  notifyHeartRequestState(inQuery: boolean) {
    this.chatService.setChatInProgress(inQuery);
  }

  newUserChatQuery(query: string): void {

  }
}
