import { KnowledgeService } from '@eva-services/knowledge/group/knowledge.service';
import { KnowledgeVersionsDraftPublished, KnowledgeDocument } from '@eva-model/knowledge/knowledge';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import * as pretty from 'pretty';
import { KnowledgeModel } from '@eva-model/knowledge';
import { MatSelectChange } from '@angular/material/select';

/**
 * Doing a diff gives us these results.
 */
interface DiffChangeObject {
  count: number; // line number
  value: string; // html string that was changed
  added?: boolean; // property exists when line was added
  removed?: boolean; // property exist when line was removed
}

/**
 * Info about the knowledge document we are comparing against.
 */
interface SelectedKnowledgeDocVersionData {
  currentDocHtml: string;
  versionDocHtml: string;
  versionDocTimestamp: number;
  docVersions: KnowledgeVersionsDraftPublished[];
  groupPublicKey: string;
  id: string;
}

/**
 * CSS classes used whether a segement was added or removed
 */
enum DiffCssClasses {
  Added = 'line-added',
  Removed = 'line-removed'
}

@Component({
  selector: 'app-knowledge-diff',
  templateUrl: './knowledge-diff.component.html',
  styleUrls: ['./knowledge-diff.component.scss']
})
export class KnowledgeDiffComponent implements OnInit {

  // state when loading version from server
  loadingVersion = false;

  // doc versions to choose from
  docVersions: KnowledgeVersionsDraftPublished[];

  // passed version to compare against
  comparingVersion: number;
  comparingVersionHtml: string;

  // selected version to compare passed version against
  selectedVersion: number;
  selectedVersionHtml: string;

  diffResult: any[] = []; // result from Diff library
  fullDiffHtml: string; // html to display on screen

  revisions: {version: number; active: boolean}[] = null;

  // TOSO: Add interface for passed data
  constructor(@Inject(MAT_DIALOG_DATA) public data: SelectedKnowledgeDocVersionData,
              private knowledgeService: KnowledgeService) { }

  async ngOnInit() {
    // Make sure you cannot select the version we are comparing against, remove it from array
    this.docVersions = this.data.docVersions.filter(v => v.version !== this.data.versionDocTimestamp);
    this.comparingVersion = this.data.versionDocTimestamp;

    this.comparingVersionHtml = this.data.versionDocHtml;

    this.loadingVersion = true;

    const response = await this.knowledgeService.getDocumentRevisions(this.data.id, this.data.groupPublicKey, true);
    this.docVersions = response.publishedVersions.map((version) => {
      return {
        version: version,
        currentVersion: this.data.versionDocTimestamp === version,
        published: true
      };
    });

    this.loadingVersion = false;
  }

  /**
   * Fired every time a select option is clicked. This is to ensure user doesn't go crazy clicking many versions.
   */
  set selectVersion(version: number) {
    this.selectedVersion = version;
  }

  /**
   * Gets a specific version of the knowledge doc from the database.
   *
   * @param version timestamp of version to retrieve
   */
  private async getDocumentVersionAndHtml(version: number): Promise<string> {
    this.loadingVersion = true;

    const response = await this.knowledgeService.getKnowledgeByVersion(this.data.groupPublicKey, this.data.id, version.toString() );

    // Check if 200 but still failed
    if (!response.success) {
      this.loadingVersion = false;
      throw new Error('failed');
    }

    const loadedDoc: KnowledgeDocument = response.additional.knowledgeDocument;

    // Create HTML string from selected doc
    this.selectedVersionHtml = new KnowledgeModel(loadedDoc).getHTMLViewString();

    this.loadingVersion = false;
    return this.selectedVersionHtml;
  }

  /**
   * Called when comparing 2 different versions of a doc.
   * Takes the version we have and a selected version and creates a comparison of them both.
   */
  public async viewComparison() {
    const Diff = (<any>window).Diff;

    // Get html of version selected to compare
    let selectedHtmlResponse: string;

    try {
      selectedHtmlResponse = await this.getDocumentVersionAndHtml(this.selectedVersion);
    } catch (err) {
      console.log('failed to get version html.');
      console.error(err);
    }

    // Format html so we can check every line for changes
    // We will also normalize space unicode characters (&nbsp;) to reduce false-positive diffs
    const comparingHtml = pretty(this.comparingVersionHtml.replace(/&nbsp;/g, ' '));
    const selectedHtml = pretty(selectedHtmlResponse.replace(/&nbsp;/g, ' '));

    // Create an array of diff change objects
    const diffResult: DiffChangeObject[] = Diff.diffLines(selectedHtml, comparingHtml);

    let concatDiffHtml = '';

    diffResult.forEach((chunk: DiffChangeObject) => {
      // If chunk does not have either property, nothing has changed.
      if (!chunk.hasOwnProperty('added') && !chunk.hasOwnProperty('removed')) {
        concatDiffHtml += chunk.value;
      }

      // If chunk is removed
      if (chunk.removed || chunk.added) {
        // Parse chunk html and add css classes to top level elements to not break html rendering
        // Get a list of elements without the enter characters, just the elements themselves.
        const parsed = Array.from(
          new DOMParser().parseFromString(chunk.value, 'text/html').body.childNodes
        ).filter(a => a.nodeName !== '#text');

        // Apply the appropriate css class and add to our doc string
        parsed.forEach((node: any) => {
          node.classList.add(chunk.added ? DiffCssClasses.Added : DiffCssClasses.Removed);
          concatDiffHtml += node.outerHTML;
        });
      }
    });

    this.fullDiffHtml = concatDiffHtml;
  }

}
