import { KnowledgeDocumentSectionFlat } from './knowledge/knowledge';
import {
  KnowledgeDocumentSection
} from '@eva-model/knowledge/knowledge';
import { LastState } from './userLastState';
import { HtmlSanitization } from '@eva-model/htmlSanitization';

/**
 * Note after this this file awhile back:
 * Instead, for tree shaking, we should not use a class and just export each function individually since they are all stateless.
 * This helps with tree shaking.
 *
 * @example
 * export function createNodeList();
 */

export class KnowledgeUtils {

  /**
   * Accepts an html string and returns a document, starting at the <html> tag
   *
   * @param htmlString string of html tags
   */
  public static parseHtmlStringToDocument(htmlString: string): Document {
    // set any target attributes as _blank and noopener
    // and do not remove them if they exist
    const HtmlSanitizer = new HtmlSanitization();
    return new DOMParser().parseFromString(HtmlSanitizer.clean(htmlString), 'text/html');
  }

  /**
   * This returns a live NodeList. It won't change in this context since are not adding more elements or anything,
   * But this NodeList is a special collection rather than an Array. You can run some Array-like commands on it like
   * forEach and a few others, but it's missing much Array functionality.
   *
   * Recommend to convert NodeList into an Array before doing operations on it using helper function (convertNodeListToArray).
   *
   * TODO: Fix this return type to be correct... Is there a type we can use that won't throw type errors?
   *
   * @param htmlString string of html tags
   */
  public static createNodeList(htmlString: string): any {
    return this.parseHtmlStringToDocument(htmlString).body.childNodes;
  }

  /**
   * Converts an HTML string into a DOM object, then takes the childNodes of that DOM object and
   * converts the NodeList into an array for easier traversal since NodeList is limited and does not
   * act exactly like an Array. Once thing to note is that a NodeList is a live list, where once converted to
   * an Array, the array will never change and be disconected from the DOM object.
   *
   * @param htmlString string of html tags
   */
  public static createNodeListAsArray(htmlString: string): Array<any> {
    const nodes = this.createNodeList(htmlString);
    const result = [];

    let i = 1;
    while (i <= nodes.length) {
      result.push(nodes[i - 1]);
      i++;
    }

    return result;
  }

  /**
   * Test if the tag name contains h1, h2, h3, h4, h5, h6
   *
   * @param {string} tagName - (h1, h2, <h1>, <h2> etc)
   * @returns {boolean} true or false
   */
  public static isHeader(tagName: string): boolean {
    const headerEleRegex = 'h[1-6]';
    return new RegExp(headerEleRegex).test(tagName);
  }

  /**
   * Test if a string starts with a list tag
   *
   * @param {string} htmlString
   * @returns {boolean} true or false
   */
  public static isList(htmlString: string): boolean {
    const listEleRegex = '(<ul|<ol)';
    return new RegExp(listEleRegex).test(htmlString);
  }

  /**
   * Tests if the block of text is html or not by testing the start and end characters for < and >
   *
   * @param {string} htmlString
   * @returns {boolean} true or false
   */
  public static isHtmlChunk(htmlString: string): boolean {
    const htmlChunkRegex = '^(<.+>)$';
    return new RegExp(htmlChunkRegex).test(htmlString);
  }

  /**
   * Flattens a tree like section
   * Loop through the entire array tree and get most information. We do omit some section data though.
   * If you need more data returned, add it and update the interface(s).
   *
   * Modified to work with our section structure.
   *
   * If docText is included as a param, it will be included in the flat output.
   * 
   * Function originates from [https://30secondsofcode.org/index#deepflatten]{@link https://30secondsofcode.org/index#deepflatten}
   *
   * @param section Sections with one or many sections as children
   * @param docText top document text
   */
  public static getSectionsAsFlatArray(section: KnowledgeDocumentSection[], docText?: string): KnowledgeDocumentSectionFlat[] {
    const result: KnowledgeDocumentSectionFlat[] = []; // end result of function
    const titlePathChar = '|'; // Seperator for the titlePath property
    let currentPath: string[] = []; // keeps track of recursive section titles
    let sectionIdLength = 0; // how we track if we should keep some of the currentPath or reset it all

    const deepFlatten = (arr: KnowledgeDocumentSection[]) => [].concat(...arr.map((v: KnowledgeDocumentSection) => {

      // Remove html tags from title
      const strippedTitle = this.stripHtmlTagsFromString(v.title);
      // Save length of section id
      sectionIdLength = v.id.split('_').length;

      if (sectionIdLength > 1) {
        // Remove the last element from the path array
        currentPath = currentPath.slice(0, sectionIdLength - 1);
      } else {
        // reset path to the beginning
        currentPath = [];
      }

      currentPath.push(strippedTitle);

      // Push our current section data to results array.
      result.push({
        title: v.title,
        id: v.id,
        text: v.text,
        titlePath: currentPath.join(titlePathChar)
      });

      // If there is a child section, push our current section properties and then call deepFlatten again on the child.
      // If there is no child section, we are at the end of this branch. Push our current section props and then return this section.
      return ((v.sections && Array.isArray(v.sections))) ? deepFlatten(v.sections) : v;
    }));

    // Execute flatten function on our parameter we passed in
    deepFlatten(section);

    // handle documentText if it is included
    if (docText && docText !== '') {
      // create a very basic section
      const docTextSection: KnowledgeDocumentSectionFlat = {
        title: '',
        titlePath: '',
        id: '0',
        text: docText
      };
      result.unshift(docTextSection);
    }

    // Done, return result array.
    return result;
  }

  /**
   * Parses the html and queries to get all <img> src's and then returns them. We use this when a document is getting updated to check
   * if any images have changed from the updated doc to what we first got when we loaded the doc from the server. This is a better
   * way to check for images changes than doing it on the editor event, as the editor also supports Undo and we cannot listen to what
   * HTML was put back in on an undo, so we just do the work and check for image changes after the document updates.
   *
   * @param htmlString {string} - any html string to be converted to a DOM
   */
  public static getImageNodesFromHtmlString(htmlString: string): any[] {
    const parsed = new DOMParser().parseFromString(htmlString, 'text/html');

    // Search for an images
    // Returns nodeList of matches
    const nodeList = parsed.querySelectorAll('img');
    const matches = [];

    // Convert the NodeList to an Array
    // TODO: We should updated the convertNodeListtoArray class function to not have the switch statement in it, so we can used it.
    if (nodeList.length > 0) {
      let i = 1;
      while (i <= nodeList.length) {
        matches.push(nodeList[i - 1]);
        i++;
      }
    }

    // Just return the src attributes
    // return matches.map(item => item.src);
    return matches;
  }

  /**
   * Pass in both the <img> src array we generated when we got the doc and the <img> src array after the updated of the doc
   * and returns any images that do not exist in our updated array
   *
   * @param initialImages list of image src when we first got the doc
   * @param updateImages list of image src when we updated the doc
   */
  public static checkForImagesRemoved(initialImages: string[], updateImages: string[]): string[] {
    // if initial images is not set, we don't need to do anything since nothing to compare against.
    if (!initialImages) {
      return [];
    }
    const set = new Set(updateImages);
    return initialImages.filter(a => !set.has(a));
  }

  /**
   * Takes a section title which is an HTML string and the section id and adds the
   * section id number into the section title as an ID attribute and then returns the full HTML string.
   * TODO: Should we just create a dom element instead and do operations that way instead of string manipulation?
   *
   * @param {string} titleElement - section title that is an HTML string
   * @param {string} sectionId - section id (1, 1_2, 3_4, etc.)
   */
  public static addSectionIdToTitleHtml(titleElement: string, sectionId: string): string {
    let result = '';

    // Check if the titleElement is a header element
    const titleMatch = titleElement.match('<h[1-6]');

    // titleElement is a header element
    if (titleMatch) {
      // Take the match which was the beginning, add in an ID attribute which consists of the section id and then
      // append the rest of the title element to close off the element.
      result += `${titleMatch[0]} id="${sectionId}"${titleElement.slice(titleMatch[0].length)}`;
    }

    return result;
  }

  /**
   * Returns a string that does not include html tags in it.
   *
   * @param {string} htmlString - html of string you want to clean.
   */
  public static stripHtmlTagsFromString(htmlString: string): string {
    return htmlString.replace(/(<([^>]+)>)/ig, '');
  }

  /**
   * Sanitize the html for any tags that should not be there. Currently, we add <data> tags for sections
   * when editing documents to highlight feedback items and where they occur in the document. When we save
   * we want to remove any data tags since we don't need them anymore as they are ONLY for display purposes.
   * Every time we save an existing doc, we need to sanitize our HTML.
   * 
   * NOTE: THIS IS NOT A DOMPURIFY FUNCTION, THIS IS JUST CUSTOM SANITIZATION OF A FEW UNWANTED TAGS.
   * NOT TO BE CONFUSED WITH CLEANING FOR XSS PUPROSES.
   *
   * @param htmlString document html
   */
  public static sanitizeHtml(htmlString: string): string {
    const parsed = new DOMParser().parseFromString(htmlString, 'text/html'); // returns document
    // test for any section wrapping tags
    // returns null or the first dom element found, convert that result to a boolean
    const sectionWrapperElementExists = !!(parsed.querySelector('[data-section-wrapper="true"]'));

    // no further sanitizing needed if no wrapper found
    if (!sectionWrapperElementExists) {
      return htmlString;
    }

    // loop all TOP LEVEL elements and scrub out any wrapping elements
    // that contains the attribute: data-section-wrapper
    // TODO: This could be better. We are parsing an html string twice, but we already parsed it above.
    const elementList = this.createNodeListAsArray( htmlString );
    let sanitizedHtmlString = ''; // collection of all appended html

    elementList.forEach((ele) => {
      if (ele && ele.dataset && ele.dataset.sectionWrapper) {
        sanitizedHtmlString += ele.innerHTML;
      } else {
        sanitizedHtmlString += ele.outerHTML;
      }
    });

    return sanitizedHtmlString;
  }

  /**
   * Pass in a section and returns the section as an HTML block with
   * the heaer including section id attributes for display.
   *
   * @param section section in its object format
   */
  public static createHtmlStringFromSection(section: KnowledgeDocumentSection): string {
    let htmlResult = '';

    if (section.title || section.title === '') {
      htmlResult += this.addSectionIdToTitleHtml(section.title, section.id);
    }

    if (section.text) {
      htmlResult += section.text;
    }

    return htmlResult;
  }

  /**
   * Parses the chunk of html and adds a target=_blank to any anchor elements found, then returns the html string back.
   *
   * @param html html chunk
   */
  public static updateAnchorTagsToOpenNewTab(html: string): string {
    const parsedHtml = KnowledgeUtils.parseHtmlStringToDocument(html);
    const anchorElements: NodeListOf<HTMLAnchorElement> = parsedHtml.querySelectorAll('a');

    // anchor elements will be a populated or empty list
    for (let i = 0; i < anchorElements.length; ++i) {
      const elem = anchorElements[i];
      elem.setAttribute('target', '_blank');
      if (KnowledgeUtils.isAnchorHrefKnowledgeLink(elem.href)) {
        elem.classList.add('disable-pointer-events');
      }
    }

    // get the result html string
    return parsedHtml.body.innerHTML;
  }

  /**
   * Check if href string contains a couple different indicators of a EVA knowledge link
   *
   * @param href anchor href attribute
   */
  public static isAnchorHrefKnowledgeLink(href: string): boolean {
    return href.includes('type=knowledge') && href.includes('id=');
  }

  public static isAnchorHrefSectionLink(ele: any): boolean {
    return ele.href.includes('#') && ele.dataset && ele.dataset.knowledgeLink === 'section';
  }

  /**
   * Takes a knowledge response that belongs in lastState and returns an html or text string to display.
   */
  public static getBestSectionMatchHTMLFromLastState(heartResponse: any): string {
    const NO_RESULTS = `No results to display. Try again.`;
    let textString = '';

    const splitIds = heartResponse.response.tfidfDocumentMatches[heartResponse.response.count].docId.split('_');
    const bestSectionId: string = splitIds.slice(2, splitIds.length).join('_');
    const docSectionsFlat = KnowledgeUtils.getSectionsAsFlatArray(heartResponse.response.knowledgeDocument.sections);
    const currentSection = docSectionsFlat.filter(item => item.id === bestSectionId);

    // if the best section id is "0", that means we need to show the text and not a section.
    if (bestSectionId === '0') {
      textString = heartResponse.response.knowledgeDocument.text;
    } else {
      // ensure there is an item in our array, else we will cause an error. Setting response to a generic message.
      if (currentSection.length > 0) {
        // found section data
        textString = currentSection[0].text;
      } else {
        // filter returned no results
        textString = NO_RESULTS;
      }
    }

    // if textString is an html string, update any anchor elements to open in a new tab/window
    if (KnowledgeUtils.isHtmlChunk(textString)) {
      textString = KnowledgeUtils.updateAnchorTagsToOpenNewTab(textString);
    }

    return textString;
  }

  /**
   * Creates a partial feedback submission object from dialogflow response.
   */
  public static getSectionFromLastState(heartResponse: any): {
    query: string;
    groupPublicKey: string;
    modelVersion: string;
    docName: string;
    docId: string;
    docVersion: number;
    docSection: string;
    sectionHtml: string;
    section: KnowledgeDocumentSectionFlat
  } {
    const splitIds = heartResponse.response.tfidfDocumentMatches[heartResponse.response.count].docId.split('_');

    const feedbackObject = {
      query: heartResponse.query,
      groupPublicKey: heartResponse.response.tfidfDocumentMatches[heartResponse.response.count].modelVersion.split('_')[0],
      modelVersion: heartResponse.response.tfidfDocumentMatches[heartResponse.response.count].modelVersion.split('_')[1],
      docName: heartResponse.response.knowledgeDocument.name,
      docId: splitIds[0],
      docVersion: Number(splitIds[1]),
      docSection: splitIds.slice(2, splitIds.length).join('_'),
      sectionHtml: KnowledgeUtils.getBestSectionMatchHTMLFromLastState(heartResponse),
      section: null
    };

    // get the section to populate the section
    feedbackObject.section = this.getSectionsAsFlatArray(heartResponse.response.knowledgeDocument.sections).find((s) => {
      return s.id === feedbackObject.docSection;
    });

    return feedbackObject;
  }

}
