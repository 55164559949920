export const environment = {
  production: false,
  env: 'Dev',
  hmr: false,
  appName: 'ATB DEVELOPMENT EVA',
  authApp: 'eva-atb-prod',
  fullPath: 'dev.atbeva.com',
  // eslint-disable-next-line max-len
  evaGlobalGroup: '04f7c3e365ae492cf4e6264e1aa9f045b211b9b4ffec230d278bf9f64ea5f50239ad780749ffc3de4c7fccc9b9025ade215f63e39d4baf976bbcd49477f6641d83',
  // eslint-disable-next-line max-len
  atbGlobalGroup: '0411a2ff45117a5f6c82bb1e2a5a84dd39ea899385be2b0f73b82e92a5a596db774d721bd04174949a3ba5cc02ee9409416c5e885e165ecaea09f4f43798792770',
  atbGlobalSubGroups: [
    '049c6177f3d550130d2abe9a8dfe9ea53738a63ada34c4a0203cb0309f04ae3c1250bc249520b6e05fd367ea924e4e27130a6e2929aeac85ad47dbdff185c343f5',
    '04a0b102b18399b04d7e9c926ec58a4b309d8db68f89ee5021d7d3f207b4e415c4d1a2de4c3788d8e92583284f8a2026e7ccb6d31e54456c7c0c8bd00d938c8fb0',
    '0433892a1fd1ef212c38cf58a593d29f30d921268ed24be318af79e9acfe966aeef48cddbaddded6614abb7e1323099e9a31bed2fab8f244eec794d6bece069cde'
  ],
  // eslint-disable-next-line max-len
  rcmGroup: '04a0e1a14fa146bbd9941b0353129e1fbae824f49c1bfe6e43fe99f109488cb63911cbd35180b52d047a9593f2bcfcd83c6a61483173014edf82518e78806890d2',
  notificationResultsPerGroupLimit: 10,   // Limited number of process notifications ready for "Action" per group.
  workingOnProcessesLimit: 10,   // Limited number of process notifications ready for "Accept"
  firebaseFunction: {
    endpoint: 'https://us-central1-eva-atb-prod.cloudfunctions.net',
    createNewScheduledTask: '/createNewScheduledTask',
    sendCommunicationLeadEmail: '/sendCommunicationLeadEmail'
    // if you want to test functions locally uncomment the line below
    // endpoint: 'http://localhost:5000/eva-atb-prod/us-central1'
  },
  firebaseConfig: {
    apiKey: 'AIzaSyCeSmy6N5LZIv7Ev9s2ThkSxhDnAOknMI4',
    authDomain: 'eva-atb-prod.firebaseapp.com',
    databaseURL: 'https://eva-atb-prod.firebaseio.com',
    projectId: 'eva-atb-prod',
    storageBucket: 'eva-atb-prod.appspot.com',
    messagingSenderId: '1068063523677',
    appId: '1:1068063523677:web:7ff5e94c988e368d'
  },
  blockConfig: {
    SIGNING_CURVE: 'p256',
    ENCRYPTION_CURVE: 'p256',
    pbkdf2: 'sha256',
    pbkdf2ByteSize: 128,
    pbkdf2IVByteSize: 32,
    globalDatabase: {
      publicKeyPath: 'PublicKeys'
    },
    types: {
      dynamicInteractions: {
        typeId: 'RmFyaGFkJ3MgYW1hemluZyBkeW5hbWljIGludGVyYWN0aW9ucw==',
        knownKeyInteractionPath: 'ATBDynamicInteractions',
        publicKeyInteractionPath: 'PublicDynamicInteractions'
      },
      groups: {
        typeId: 'SSBmZWxsIGRvd24uIE15IGdyb3VwIG9mIGZyaWVuZHMgcGlja2VkIG1lIHVwLg==',
        groupSigningPath: 'GroupSigningKeys',
        knownGroupEncryptionPath: 'ATBGroupEncryptionKeys',
        types: {
          dynamicData: 'RG9uJ3QgbGV0IHlvdXIgZGF0YSBnbyBzdGF0aWMsIGJlIGR5bmFtaWM=',
          flowThrough: 'QmVuIHNheXMgImdvIHdpdGggdGhlIGZsb3ci',
          invitation: 'SWxpa2VEaW5uZXJQYXJ0aWVzIQ==',
          knowledge: 'VGhlIG9ubHkgdHJ1ZSB3aXNkb20gaXMgaW4ga25vd2luZyB5b3Uga25vdyBub3RoaW5n',
          normal: 'Tm9ybWFsR3JvdXBSdWxlcw=='
        }
      },
      workflows: {
        typeId: 'VG8gd2FsayB1cHN0cmVhbSBpbiBhIHJpdmVyIGFuZCB0aHJvdWdoIGEgd2F0ZXJmYWxsLCBpcyBoYXJkIQ==',
        knownKeyWorkflowPath: 'ATBWorkflows',
        publicKeyWorkflowPath: 'PublicWorkflows'
      }
    }
  },
  searchConfig: {
    REQUESTER_EMAIL_KEY: 'b870cfb6-bfa3-45e2-b171-ac10af80de49',
    REQUIREMENT_EMAIL_KEYS: [
      '5ad66abb-5562-492f-ab08-b4d3ee3ff9a4',
      'a0b8bf4e-597f-46eb-91be-ffed82f0397b',
      '0a0c5e0d-8477-429d-84e9-073f55ba2fd5'
    ]
  },
  endPoints: {
    EVA_HEART: {
      url: 'https://us-central1-eva-heart-test.cloudfunctions.net/',
      query: 'incomingEVAQuery',
      addTraining: 'trainingEVAData',
      addUpdateKnowledge: 'addUpdateKnowledge',
      knowledgeByGroupDocIdAndVersion: 'getEVAKnowledgeByGroupDocIdAndVersion',
      newestKnowledgeByGroupDocId: 'getNewestEVAKnowledgeByGroupDocId',
      addKnowledge: 'addEVAKnowledge',
      getKnowledge:  'getEVAKnowledge',
      getKnowledgeList: 'getEVAKnowledgeList',
      insertKnowledge: 'insertEVAKnowledge',
      updateKnowledge: 'updateEVAKnowledge',
      addRemovedImages: 'addRemovedImages',
      deleteRestoreKnowledge: 'deleteRestoreKnowledgeDoc',
      createNewScheduledTask: 'createNewScheduledTask',
      exportKnowledge: 'exportKnowledge'
    },
    EVA_STORAGE: {
      urlATB: 'https://us-central1-eva-links-atb-test.cloudfunctions.net/',
      urlNonATB: 'https://us-central1-eva-links-test.cloudfunctions.net/',
      addLocationData: 'storeEVAEncryptedData',
      getLocationData: 'getEVAEncryptedData'
    },
    DYNAMIC_INTERACTIONS: {
      url: 'https://us-central1-atb-dynamic-form.cloudfunctions.net/'
    },
    SEARCH: {
      url: 'https://us-central1-atb-eva-search.cloudfunctions.net/'
    },
    WORKFLOW: {
      url: 'https://us-central1-atb-workflow.cloudfunctions.net/'
    },
    BLOCK_ENDPOINT: {
      url: 'https://us-central1-block-genesis.cloudfunctions.net/'
    },
    GOOGLE_DRIVE: {
      url: 'https://www.googleapis.com/drive/v3/'
    },
    EVA_FF: {
      url: 'https://us-central1-eva-atb-prod.cloudfunctions.net/',
      getActiveWorkflowById: 'getActiveWorkflowById'
    }
  },
  algolia: {
    appId: 'EV76H5OXCD',
    apiKey: '',
    indexEnding: '_TEST'
  },
  froala: {
    key: '4NC5fE4C3E4E3B2C4B-16UJHAEFZMUJOYGYQEa2d2ZJd1RAeF3C8B5E5E3D3G2G3A17A6=='
  },
  cpAddressCompleteFind: {
    url: 'https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Find/2.1/json3.ws',
    key: 'fe81-fc68-bj96-cb99'
  },
  cpAddressCompleteRetrieve: {
    url: 'https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Retrieve/v2.10/json3.ws',
    key: 'fe81-fc68-bj96-cb99'
  },
  defaultTutorials: {
    // this is a list of tutorial definitions and the current completion value defaulted to false
    //
    // Tutorials Attributes: tutorials are defined by adding key: value pair attributes (ie. "welcome: false")
    //    adding a new tutorial is as simple as adding a new attribute (ie. "new_tutorial_name: false")
    //
    // Versioning: increment the version number to indicate that the list of tutorials has changed and all users
    //    will have their list of tutorials updated by the tutorials service
    //
    // sample usage: tutorial usage pattern example can be found in the saltchat component
    version: 7,
    tutorialList: {
      welcome: false,
      feedback: false,
      processDashboard: false,
      documentFeedback: false
    }
  }
};
