import { KnowledgeRevisionsComponent } from '@eva-knowledge/knowledge-revisions/knowledge-revisions.component';
import { StorageService } from '@eva-services/storage/storage.service';
import { KnowledgeReturnObject } from '@eva-model/return-objects/returnObjects';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import { KnowledgeDocument, KnowledgeRemoveImages, KnowledgeEditorControls} from '@eva-model/knowledge/knowledge';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Component, OnInit, NgZone, OnDestroy, TemplateRef } from '@angular/core';
import { KnowledgeModel } from '@eva-model/knowledge';
import { KnowledgeService } from '@eva-services/knowledge/group/knowledge.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { LoggingService } from '@eva-core/logging.service';
import { Subscription, Subject, zip, BehaviorSubject, Observable, of } from 'rxjs';
import { GroupType, Group } from '@eva-model/group';
import { filter, take, map, tap, delay, find, debounceTime } from 'rxjs/operators';
import { KnowledgeUtils } from '@eva-model/knowledgeUtils';
import { KnowledgeConstants } from '@eva-knowledge/knowledge-constants';
import { KnowledgeFindAndReplaceDialogComponent
} from '@eva-knowledge/knowledge-find-and-replace-dialog/knowledge-find-and-replace-dialog.component';
import { KnowledgeFeedbackService } from '@eva-services/knowledge/feedback/knowledge-feedback.service';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { KnowledgeDeleteComponent } from '../knowledge-delete/knowledge-delete.component';
import { KnowledgeDocumentSearchComponent } from '../knowledge-document-search/knowledge-document-search.component';
import { AnnounceKnowledgeShow } from '@eva-model/chat/knowledge/chatKnowledge';
import { ChatKnowledgeService } from '@eva-services/chat/knowledge/chat-knowledge.service';
import FroalaEditor from 'froala-editor';
import { MultiViewService } from '@eva-services/home/multi-view/multi-view.service';
import { ChatService } from '@eva-services/chat/chat.service';
import { Routes } from '@eva-model/menu/defaults/mainMenu';
import { ThemeService } from '@eva-services/theme/theme.service';
import { KnowledgeGlobalFindReplaceService } from '@eva-services/knowledge/knowledge-global-find-replace.service';
import Mark from 'mark.js';
import { GeneralDialogComponent } from '@eva-ui/general-dialog/general-dialog.component';
import { GeneralDialogModel } from '@eva-model/generalDialogModel';
import { KnowledgeDocumentSectionComponent } from '../knowledge-document-section/knowledge-document-section.component';

@Component({
  selector: 'app-knowledge-create',
  templateUrl: './knowledge-create.component.html',
  styleUrls: ['./knowledge-create.component.scss']
})
export class KnowledgeCreateComponent implements OnInit, OnDestroy {

  // contains all active subscriptions for this component.
  componentSubs = new Subscription();

  // state
  loadingDoc = true;
  loadingDifferentVersion = false;
  isSaving = false;
  savingDialog: MatDialogRef<any>;
  mode = KnowledgeConstants.KNOWLEDGE_MODE.CREATE; // initial is create, unless editing existing doc.

  knowledgeDoc: KnowledgeModel; // existing or new knowledge document

  // Image handling
  filesToUpload: {
    elementId: string,
    fileName: string,
    path: string,
    data: File | Blob
  }[] = [];

  // Array of image src that exists in an already created document.
  preExistingImages: string[] = null;

  // Subject containing the file from the editor imageUpload event.
  fileEvent$: Subject<File|Blob> = new Subject();

  // Subject containing the element from the editor imageUpload event that was created and inserted into the editor html.
  imageDomEvent$: Subject<{el: any, editor: any}> = new Subject();

  // Contains the controls for the editor and the editor instance
  editorControls: BehaviorSubject<KnowledgeEditorControls> = new BehaviorSubject(null);

  // If we want the editor controls or the instance, subcribe to this observable
  editorControls$: Observable<KnowledgeEditorControls> = this.editorControls.asObservable().pipe(
    filter(v => v !== null),
    take(1)
  );

  unsavedChanges: BehaviorSubject<boolean> = new BehaviorSubject(false); // monitors any changes to the editor that are not saved via api.
  editorContentChanged: Subject<boolean> = new Subject();

  // updated by the editor event contentChanged
  unsavedChanges$: Observable<boolean> = this.unsavedChanges.asObservable().pipe(
    tap((unsaved) => { if (unsaved) {this.clearHighlightedHtmlElements()}}),
    delay(500) // wait for binding to update
  );

  showOutOfDateWarning = false; // true when viewing a doc version that was not the initial versino the page was loaded with.

  // bound to the froalaEditor. Contains the html string of what is in the editor...
  // this can be sometimes out of date though so we also use editorControls.
  froalaModel: string;

  // the edit element of the froala editor
  froalaElement: HTMLElement;

  // 
  froalaInstance: any;

  // used for comparing changes against the html in the editor, only set/changed on load or save
  initialFroalaModel = '';

  froalaOptions = {
    pasteAllowedStyleProps: ['width', 'height'], // https://www.froala.com/wysiwyg-editor/docs/options#pasteAllowedStyleProps
    pasteDeniedAttrs: ['id', 'class', 'contenteditable', 'aria-.*'], // we manually place section id's
    pasteDeniedTags: ['hr', 'blockquote', 'script', 'iframe', 'span[aria-.*]'],
    htmlAllowedEmptyTags: ['textarea', 'span', 'object', 'video', '.fa', 'mark'],
    angularIgnoreAttrs: ['script'],
    colorsText: ['#0ba1e0', '#000'],
    toolbarButtons: KnowledgeConstants.EDITOR.TOOLBAR_BUTTONS,
    imageInsertButtons: KnowledgeConstants.EDITOR.IMAGE_UPLOAD_BUTTONS,
    imageEditButtons: KnowledgeConstants.EDITOR.IMAGE_EDIT_BUTTONS,
    linkInsertButtons: KnowledgeConstants.EDITOR.LINK_INSERT_BUTTONS,
    linkEditButtons: KnowledgeConstants.EDITOR.LINK_EDIT_BUTTONS,
    spellcheck: true,
    imageSplitHTML: true,
    imageUploadRemoteUrls: false,
    htmlUntouched: true,
    placeholderText: 'Start typing a new document...',
    key: environment.froala.key,
    attribution: false,
    events: {
      'initialized': (e) => {
        this.froalaElement = e.getEditor().el;
        this.froalaInstance = e.getEditor();
      },
      'paste.afterCleanup': (html) => {
        // Parse string coming in from the paste so we can manipulate it
        const tempDOM = new DOMParser().parseFromString(html, 'text/html');
        const listItems = tempDOM.querySelectorAll('li'); // returns a static NodeList

        // clean any list items where the first child is a paragraph element
        // this prevents paragraph tags being inside of LI elements
        listItems.forEach((li) => {
          if (li.firstChild.nodeName === 'P') {
            li.firstChild.replaceWith(...Array.from(li.firstChild.childNodes));
          }
        });

        // Loop through all body nodes in the editor
        const rootEditorNodes = tempDOM.body.childNodes; // returns a live NodeList

        // console.log('pastedNodes:');
        // console.log(Array.from(rootEditorNodes));

        // Nodes that are in the root of the knowledge document. All elements are wrapped in these.
        const allowedWrappingNodes = ['P', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'UL', 'OL', 'TABLE']
        // Keep reference to nodes not allowed as a wrapping node
        const orphanedNodeGroupings: Array<Array<ChildNode>> = []

        let orphanNodeGrouping = [];

        rootEditorNodes.forEach((node) => {
          if (!allowedWrappingNodes.includes(node.nodeName)) {
            orphanNodeGrouping.push(node);
          } else {
            // Reset group of nodes
            if (orphanNodeGrouping.length) {
              orphanedNodeGroupings.push(orphanNodeGrouping)
            }
            orphanNodeGrouping = [];
          }
        });

        if (orphanNodeGrouping.length) {
          orphanedNodeGroupings.push(orphanNodeGrouping)
        }

        // If there are nodes that need action
        if (orphanedNodeGroupings.length) {
          orphanedNodeGroupings.forEach((groupIndexes: Array<ChildNode>) => {
            const newWrappingElement = document.createElement('p');
            // Insert into dom so we don't lose position
            tempDOM.body.insertBefore(newWrappingElement, groupIndexes[0]);
            groupIndexes.forEach((node) => {
              newWrappingElement.appendChild( node )
            })
          })
          
          // console.log('Done changing nodes:');
          // console.log(rootEditorNodes);

        }

        // P tags removed from inside LI elements.
        let modifiedHtml: string = tempDOM.body.innerHTML;
      
        return modifiedHtml;
      },
      'paste.beforeCleanup': (clipboard: string): string => {
        // Check for any unwanted strings here before paste occurs
        let cleanedHtml = clipboard;
        cleanedHtml = cleanedHtml.replace(/ dir="ltr"/g, ''); // Comes from Google Docs, etc.
        return cleanedHtml
      },
      'paste.after': () => {
        // Remove all isPasted id attributes from pasted elements until there are none left
        do {
          const isPastedElem = document.getElementById('isPasted');
          if (isPastedElem) {
            isPastedElem.removeAttribute('id')
          }
        } while (!!document.getElementById('isPasted'))
      },
      'image.beforeUpload': (images: any[]) => {
        // This event is good because it returns us the actual file.
        // Since you cannot drop multiple images into the editor, lets grab the first
        // file and push it through our Observable.
        this.zone.run(() => {
          this.fileEvent$.next(images[0]);
        });
      },
      'image.inserted': ($img: any[]) => {
        // Create a temporary ID to search the DOM for.
        const GENERATED_ID = `IMG_${Date.now()}_${Math.floor(Math.random() * 1000)}`;

        // Use jQuery to add generated ID to DOM element
        $img[0].setAttribute('id', GENERATED_ID);

        this.zone.run(() => {
          this.imageDomEvent$.next($img[0]);
        });
      },
      'image.replaced': ($img: any[]) => {
        this.zone.run(() => {
          // Check if the image being removed has an ID, if it does, check and see if the image was pending upload.
          const elementId = $img[0].getAttribute('id');
          if (elementId) {
            this.removeFileFromPendingUpload(elementId);
          }
        });
      },
      'image.removed': ($img: any[]) => {
        this.zone.run(() => {
          // Check if the image being removed has an ID, if it does, check and see if the image was pending upload.
          const elementId = $img[0].getAttribute('id');
          if (elementId) {
            this.removeFileFromPendingUpload(elementId);
          }
        });
      }
    }
  };

  knowledgeGroups: Group[] = []; // knowledge groups user is a member of populate here.
  versions: number[] = []; // all versions of the doc, if doc doesn't exist then array is empty.
  publishedVersions: number[] = []; // all published versions of the doc, if unpublished then array is empty.
  isDeleted = false; // whether the base doc is in a deleted state.
  viewKey: string; // passed in order to bypass if a document is archived.
  _editDocInit;
  set editDocInit(editDocInit) {
    this._editDocInit = editDocInit;
    if (this._editDocInit && this._editDocInit.froalaModel) {
      setTimeout(() => {
        this.froalaModel = this._editDocInit.froalaModel;
        if (this._editDocInit.docResult) {
          this.docResult = this._editDocInit.docResult;
          this.initializeKnowledgeEditor(this.docResult, true);
        } else {
          this.initModeCreateDoc(true);
        }
      }, 500);
    }
  }
  tabIndex: number;
  docResult: KnowledgeReturnObject;

  // subscribe to this in the view
  showFeedbackPanel$: Observable<boolean> = this.knowledgeFeedbackService.activeFeedback$;

  // if actioning feedback, could be times when html is highlighted. track those highlighted elements here.
  highlightedHtmlElements: HTMLElement[] = [];

  /** Find and replace stuff */
  findAndReplaceVisible = false;
  findAndReplaceMarkInstance: Mark; 
  findAndReplaceStatus: any;
  findAndReplaceOccurences: {element: HTMLElement, elementId: string}[];
  findAndReplaceIndex: number;
  // findAndReplaceOccurences: {node:HTMLElement, viewed: boolean, replaced: boolean}[] = null;

  docForm: UntypedFormGroup; // form controls for the doc (name, group, etc)
  findAndReplaceForm: UntypedFormGroup;
  uniqueTabId: string;
  isUserMemberOfGroup: boolean = true;

  constructor(public evaGlobal: EvaGlobalService,
              public dialog: MatDialog,
              public zone: NgZone,
              public knowledgeService: KnowledgeService,
              private route: ActivatedRoute,
              private router: Router,
              private loggingService: LoggingService,
              private storage: StorageService,
              private knowledgeFeedbackService: KnowledgeFeedbackService,
              private chatKnowledgeService: ChatKnowledgeService,
              private chatService: ChatService,
              private multiViewService: MultiViewService,
              private themeService: ThemeService,
              public findAndReplaceService: KnowledgeGlobalFindReplaceService) {}

  ngOnInit(): void {
    // Clear any files to upload on init
    this.filesToUpload = [];

    this.loadingDoc = true;

    this.docForm = new UntypedFormGroup({
      name: new UntypedFormControl('', Validators.required),
      draft: new UntypedFormControl(false, Validators.required),
      group: new UntypedFormControl('', Validators.required),
      revisionNote: new UntypedFormControl('')
    });

    this.findAndReplaceForm = new UntypedFormGroup({
      find: new UntypedFormControl('', Validators.required),
      replace: new UntypedFormControl(''),
      matchCase: new UntypedFormControl(false)
    });

    // Subscribe to the find control for re-highlighting document
    this.componentSubs.add(
      this.findAndReplaceForm.get('find').valueChanges.pipe(
        debounceTime(500)
      ).subscribe((findTerm: string) => {
        if (!findTerm) {
          this.findAndReplaceReset();
          return;
        }
        this.highlightTermInDocument(findTerm.trim());
        // this.froalaModel = this.knowledgeDoc.getHTMLEditString();
        // this.froalaModel = this.froalaInstance.el.innerHTML;
        this.goToInitialFoundTerm();
      })
    );

    this.componentSubs.add(
      this.findAndReplaceForm.get('matchCase').valueChanges.pipe(
        debounceTime(500)
      ).subscribe(() => {
        const findTerm = this.findAndReplaceForm.get('find').value;
        if (!findTerm || !findTerm.trim()) {
          return;
        }
        this.highlightTermInDocument(findTerm);
        // this.froalaModel = this.knowledgeDoc.getHTMLEditString();
        // this.froalaModel = this.froalaInstance.el.innerHTML;
        this.goToInitialFoundTerm();
      })
    );

    // Subscribe to the draft control so we can update the view save button with the proper verbiage
    this.componentSubs.add(
      this.docForm.get('draft').valueChanges.subscribe(
        (value: boolean) => { this.knowledgeDoc.updateDraftStatus = value; }
      )
    );

    this.componentSubs.add(
      this.multiViewService.closeTab$.pipe(
        filter((closeTab) => closeTab && this.knowledgeDoc && closeTab.entityType === 'Knowledge'),
        filter((closeTab) =>  this.uniqueTabId === closeTab.entityId),
        filter((closeTab) => this.tabIndex === closeTab.tabIndex),
      ).subscribe(closeTab => {
        this.canDeactivate().subscribe(value => {
          if (value) {
            setTimeout(() => {
              closeTab.closeSubject.next(true);
            });
          }
        });
      })
    );

    this.componentSubs.add(
      this.editorContentChanged.pipe(
        filter(() => !!(this.froalaInstance)),
        debounceTime(450)
      ).subscribe(() => {
        // notify unsaved changes
        this.unsavedChanges.next(true);

        // find and replace
        const findTerm = this.findAndReplaceForm.get('find').value; 
        if (!findTerm) {
          return;
        }
        this.highlightTermInDocument(findTerm);
      })
    )

    this.componentSubs.add(
      this.unsavedChanges.asObservable().pipe(
        filter(_ => this.isEditorContentChanged(this.froalaModel)),
        delay(500)
      ).subscribe(changes => {
        if (changes) {
          // const tab = this.multiViewService.tabs[Routes.Knowledge][this.tabIndex];
          if (!this._editDocInit) {
            this._editDocInit = {};
          }
          this._editDocInit.froalaModel = this.froalaModel;
          this._editDocInit.docResult = this.docResult;
          // tab.additionalInstanceData.editDocInit = this._editDocInit;
          // this.multiViewService.updateTabsAndSaveToLastState(Routes.Knowledge, "Update", tab, this.tabIndex);
        }
      })
    );

    let idParam: string; // doc id
    let groupParam: string; // doc group public key
    let versionParam: string; // specific version of doc we are requesting (optional) overrides published
    let publishedParam: string; // get get published version or non published version (optional)


    // Check query params once and initialize the component based on if these are set
    this.route.queryParams.pipe(
      take(1)
    ).subscribe((params) => {
      idParam = params.id;
      groupParam = params.group;
      versionParam = params.version;
      publishedParam = params.published;

      if (params.viewKey) {
        this.viewKey = params.viewKey;
      }
    });

    if (this._editDocInit) {
      this.viewKey = this._editDocInit.viewKey;
      if (this._editDocInit.froalaModel) {
        setTimeout(() => {
          this.froalaModel = this._editDocInit.froalaModel;
          if (this._editDocInit.docResult) {
            this.docResult = this._editDocInit.docResult;
            this.initializeKnowledgeEditor(this.docResult, true);
          } else {
            this.initModeCreateDoc(true);
          }
        }, 500);
      } else {
        this.initModeUpdateDoc(this._editDocInit.id, this._editDocInit.group, this._editDocInit.published,
          this._editDocInit.version);
      }
    } else {
      if (idParam && groupParam) {
        // if the id and group are defined, we are updating a knowledge document
        this.initModeUpdateDoc(idParam, groupParam, publishedParam, versionParam);
      } else {
        this.initModeCreateDoc();
      }
    }

    // get only knowledge groups the user belongs to
    // this.setknowledgeGroups();

    /**
     * Begin custom functions for the editor
     */
    // FroalaEditor.DefineIconTemplate('externalLinkIcon', '<i class="fa fa-external-link" aria-hidden="true"></i>');
    FroalaEditor.DefineIconTemplate('material_design', '<span class="material-icons">[NAME]</span>');
    FroalaEditor.DefineIconTemplate('material_icon_svg', '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000">[PATH]</svg>')
    FroalaEditor.DefineIcon('findAndReplace', {PATH: '<path d="M0 0h24v24H0V0z" fill="none"/><path d="M11 6c1.38 0 2.63.56 3.54 1.46L12 10h6V4l-2.05 2.05C14.68 4.78 12.93 4 11 4c-3.53 0-6.43 2.61-6.92 6H6.1c.46-2.28 2.48-4 4.9-4zm5.64 9.14c.66-.9 1.12-1.97 1.28-3.14H15.9c-.46 2.28-2.48 4-4.9 4-1.38 0-2.63-.56-3.54-1.46L10 12H4v6l2.05-2.05C7.32 17.22 9.07 18 11 18c1.55 0 2.98-.51 4.14-1.36L20 21.49 21.49 20l-4.85-4.86z"/>', template: 'svg'});
    FroalaEditor.DefineIcon('linkToKnowledgeDocument', {PATH: '<g><rect fill="none" height="24" width="24"/></g><g><g><g><path d="M7,17h1.09c0.28-1.67,1.24-3.1,2.6-4H7V17z"/></g><g><path d="M5,19V5h14v7h1c0.34,0,0.67,0.04,1,0.09V5c0-1.1-0.9-2-2-2H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h3.81 c-0.35-0.61-0.6-1.28-0.72-2H5z"/></g><g><rect height="4" width="4" x="7" y="7"/></g><g><rect height="4" width="4" x="13" y="7"/></g><path d="M16,20h-2c-1.1,0-2-0.9-2-2s0.9-2,2-2h2v-2h-2c-2.21,0-4,1.79-4,4c0,2.21,1.79,4,4,4h2V20z"/><path d="M20,14h-2v2h2c1.1,0,2,0.9,2,2s-0.9,2-2,2h-2v2h2c2.21,0,4-1.79,4-4C24,15.79,22.21,14,20,14z"/><polygon points="20,19 20,17 17,17 14,17 14,19 19,19"/></g></g>', template: 'svg'});
    FroalaEditor.DefineIcon('linkToDocumentSection', {PATH: '<path d="M0 0h24v24H0V0z" fill="none"/><path d="M6 7h2.5L5 3.5 1.5 7H4v10H1.5L5 20.5 8.5 17H6V7zm4-2v2h12V5H10zm0 14h12v-2H10v2zm0-6h12v-2H10v2z"/>', template: 'svg'});

    FroalaEditor.RegisterCommand('findAndReplace', {
      title: 'Find and Replace Text',
      focus: false,
      undo: false,
      refreshAfterCallback: false,
      callback: () => {
        this.zone.run(() => {
          this.showFindAndReplaceDialog();
        });
      }
    });

    FroalaEditor.RegisterCommand('linkToKnowledgeDocument', {
      title: 'Insert Link to Knowledge Document',
      focus: false,
      undo: true,
      refreshAfterCallback: false,
      callback: (e) => {
        this.zone.run(() => {
          this.showDocumentSearchDialog();
        });
      }
    });

    FroalaEditor.RegisterCommand('linkToDocumentSection', {
      title: 'Insert Link to Document Section',
      focus: false,
      undo: true,
      refreshAfterCallback: false,
      callback: () => {
        this.zone.run(() => {
          this.showDocumentSectionDialog();
        });
      }
    });

    /**
     * End custom functions for the editor
     */

    // --- Image handling ---
    // Listen to when a file is placed in the editor and create an item for queue
    this.componentSubs.add(
      zip(
        this.imageDomEvent$,
        this.fileEvent$
      ).subscribe(([htmlEle, data]) => {
        // Add this image data to the queue
        this.prepareFileForUpload(htmlEle, data);
      })
    );

    // Listen for any feedback highlight events. Will do nothing if the section was not found.
    this.componentSubs.add(
      this.knowledgeFeedbackService.announceSectionHighlight$.subscribe(
        (sectionId) => {
          // ensure to clear any elements that were highlighted
          this.clearHighlightedHtmlElements();

          // query the document for the section id element
          const sectionElement: HTMLElement = document.getElementById(sectionId);

          if (!sectionElement) {
            // did not find the element
            return;
          }

          this.setHtmlElementAsHighlighted(sectionElement);

          // scroll element into view with a slight offset so it's not below the editor toolbar
          sectionElement.scrollIntoView();
          window.scrollBy(0, -150); // scroll with an offset

          let nextSectionSibling: HTMLElement = sectionElement.nextElementSibling as HTMLElement;

          // loop all next siblings until this whole selection is highlighted, stopping at the next section id or null.
          while (nextSectionSibling && (!nextSectionSibling.id || nextSectionSibling.id === '')) {
            this.setHtmlElementAsHighlighted(nextSectionSibling);
            nextSectionSibling = nextSectionSibling.nextElementSibling as HTMLElement;
          }
        }
      )
    );

    this.chatService.setChatMinimizedState(true);

    // set the froala theme depending on EVA theme
    if (this.themeService.currentThemeType === 'dark') {
      this.froalaOptions['theme'] = 'dark';
    }
  }

  /**
   * Unsub from main subscription tracking all observables
   */
  ngOnDestroy(): void {
    if (this.componentSubs) {
      this.componentSubs.unsubscribe();
    }
    // hide feedback panel
    this.knowledgeFeedbackService.hideFeedbackPanel();
  }

  /**
   * Route guard on this route. Hits this function whenever leaving. We check if there are unsaved changes in the editor.
   */
  canDeactivate = (): Observable<boolean> => {
    if (this.unsavedChanges.getValue()) {
      // Use a window.confirm to stall the page and halt everything.
      return of(window.confirm(
        "There are unsaved editor changes. Navigate away from page without saving changes? The changes will be lost."
        ));
    }

    // No unsaved changes, return an observable of true
    return of(true);
  }

  get shouldSaveAsDraft(): boolean { return this.docForm.get('draft').value; }

  /**
   * Sets the component to know we are updating an existing document.
   * Gets the document if an id exists in the route (url).
   *
   * @param {string} docId - id of document
   * @param {string} docGroupPk - group pk doc belongs to
   * @param {string} published - to get the published version or not !! if this param even exists, it will get the published version
   * @param {string} version - to get a specific version - !! this overrides published param (see below)
   */
  async initModeUpdateDoc(docId: string, docGroupPk: string, published: string, version: string): Promise<void> {
    this.mode = KnowledgeConstants.KNOWLEDGE_MODE.UPDATE;

    try {
      // Get the document we want to update
      let docResult: KnowledgeReturnObject;

      // If a version has been passed as an argument, override published and get that version.
      // We are testing if version is of a type that is not undefined, else it was passed as undefined.
      if (!!(version)) {
        docResult = await this.knowledgeService.getKnowledgeByVersion(docGroupPk, docId, version, this.viewKey);
      } else {
        const publishedVersion = !!(published);
        docResult = await this.knowledgeService.getNewestKnowledge(docGroupPk, docId, publishedVersion, this.viewKey);
      }

      if (!docResult.success) {
        this.handleDocRetrievalError();
        return;
      }
      this.docResult = docResult;

      this.initializeKnowledgeEditor(docResult);

    } catch (err) {
      this.handleDocRetrievalError();
    }
  }

  initializeKnowledgeEditor(docResult: KnowledgeReturnObject, fromLastState = false) {
    // Handle setting up the component with the doc
    this.setDocumentProperties(docResult?.additional?.knowledgeDocument, fromLastState);

    // Handle component state if the doc is in a deleted state
    this.setIsDeleted(docResult?.additional?.isDeleted);

    // Create a reference to the versions that exist
    this.versions = docResult?.additional?.versions;
    this.publishedVersions = docResult?.additional?.publishedVersions;
  }

  /**
   * Sets the component state if the doc is deleted or not.
   *
   * @param isDeleted - whether the base doc is deleted
   */
  setIsDeleted(isDeleted: boolean) {
    this.isDeleted = isDeleted;
  }

  /**
   * Sets the component to know we are creating a new document, that doesn't exist.
   */
  initModeCreateDoc(fromLastState = false): void {
    this.mode = KnowledgeConstants.KNOWLEDGE_MODE.CREATE;

    // Create a new knowledge model for initialization
    this.knowledgeDoc = new KnowledgeModel();

    this.setknowledgeGroups();

    if (!fromLastState) {
      // Set the froala model as an empty string
      this.froalaModel = '';
    }
    this.resetUnsavedChanges();

    // Show editor
    this.loadingDoc = false;
  }

  /**
   * Gets the users groups and then filters to knowledge groups and sets a property
   */
  setknowledgeGroups(): void {
    const groupTypes = new GroupType();

    // create observable to subscribe to and checks if groups are ready and returns any knowledge groups.
    const userKnowledgeGroups$ = this.evaGlobal.userGroupsChange$.pipe(
      map(() => {
        if (this.evaGlobal.userGroups) {
          return this.evaGlobal.userGroups.filter((group) => {
            return group.groupType === groupTypes.knowledge;
          });
        } else {
          return [];
        }
      })
    );

    this.componentSubs.add(
      userKnowledgeGroups$.subscribe((groups: Group[]) => {
        this.knowledgeGroups = groups;
        // If we are looking at an existing doc, check that the user has direct membership to the group that owns the document.
        if (!this.knowledgeDoc.isNewDoc) {
          this.isUserMemberOfGroup = groups.map(group => group.groupPublicKey).includes(this.knowledgeDoc.groupPublicKey)
        }
      })
    );
  }

  /**
   * Gets the latest version of the document. This function is used after a save has happened and the user wants to keep editing.
   * When a new version of the document is saved, the response doe not return the updated document. If the user wants to continue
   * making changes, we need to get the latest doc. Expects a doc is already set in the component.
   */
  async getLatestDocVersion(): Promise<KnowledgeDocument> {
    try {
      // Get the latest version based on the 0 index of versions.
      const latestDoc = await this.knowledgeService.getKnowledgeByVersion(
        this.knowledgeDoc.groupPublicKey,
        this.knowledgeDoc.id,
        String(this.versions[0]),
        this.viewKey
      );

      if (!latestDoc.success) {
        throw new Error('unable to get latest doc');
      }

      // Set new properties with updated doc
      this.setDocumentProperties(latestDoc.additional.knowledgeDocument);

      // Unfreeze input
      this.disableAllInputs(false);

      // Set version warning to false if it's true
      this.showOutOfDateWarning = false;

      return latestDoc.additional.knowledgeDocument;
    } catch (err) {
      throw new Error(err);
    }
  }

  /**
   * Called from the template on the froala editor directive. When the editor controls are ready, pass the controls
   * to our editorControls subscription where we can initialize, destroy and get the instance of the editor whenever
   * we would like. I've found this is the best way to handle getting the current editor instance.
   *
   * @param {KnowledgeEditorControls} editorControls - controls for the froala editor
   */
  initialize(editorControls: KnowledgeEditorControls): void {
    editorControls.initialize();
    this.editorControls.next(editorControls);
  }

  /**
   * This function is called whether it's a new document or an existing one. We get the FormGroup values here and set
   * information on our doc object. We also show custom error messages depending if the doc has an ID or not. IF the doc
   * has an ID, we can safely assume it exists.
   */
  async saveDocument(savingTemplate: TemplateRef<any>): Promise<void> {
    // Check if name and group has not been set
    // Check if there is data in the editor
    // If not, do nothing.
    if (this.docForm.invalid || this.froalaModel === '') {
      return;
    }

    // Set page state
    this.savingDoc(true, savingTemplate);

    // clear any find and replace elements
    if (this.findAndReplaceMarkInstance) {
      this.findAndReplaceReset();
    }

    // get latest updates to the document
    // Remove any &nbsp; text because Froala likes to place those in.
    // If anything else added here, break into it's own cleaning function.
    this.froalaModel = this.froalaElement.innerHTML.replace(/\&nbsp;/g, ' ');

    // clear any selected sections
    this.clearHighlightedHtmlElements();

    // Get doc form values and apply to our doc data model
    this.knowledgeDoc.updateName = this.docForm.get('name').value;
    this.knowledgeDoc.updateDraftStatus = this.docForm.get('draft').value;
    this.knowledgeDoc.updateGroupPublicKey = this.docForm.get('group').value;
    this.knowledgeDoc.updateRevisionNote = this.docForm.get('revisionNote').value;

    try {
      if (!this.knowledgeDoc.isNewDoc) {
        // doc has an id, it exists
        const updatedDoc = await this.updateExistingDocument();

        // Doc has been updated, now let's check if any pre-existing images have been removed from the document.
        // Get list of images existing in the updated document
        const updatedDocImages: string[] = KnowledgeUtils.getImageNodesFromHtmlString(this.froalaModel).map(ele => ele.src);

        // Compare the document images coming in with the new images
        const imagesRemoved: string[] = KnowledgeUtils.checkForImagesRemoved(this.preExistingImages, updatedDocImages);

        // Images were removed from when we first got the doc from the server
        if (imagesRemoved.length > 0) {
          const imageRemovalRequest: KnowledgeRemoveImages = {
            groupPublicKey: this.knowledgeDoc.groupPublicKey,
            docId: this.knowledgeDoc.id,
            version: updatedDoc.additional.versionNumber,
            removed: imagesRemoved.map((src: string) => ({imageSrc: src}))
          };

          // Create docs under our new doc version of removed images
          await this.knowledgeService.markImagesRemovedFromKnowledge(imageRemovalRequest);
        }

        this.preExistingImages = updatedDocImages;

        this.loggingService.logMessage('Document updated.', false, 'success', null, 5000, 'OK');

        this.savingDoc(false);
      } else {
        // doc has no id, creating new doc
        const newDoc = await this.createNewDocument();

        this.preExistingImages = KnowledgeUtils.getImageNodesFromHtmlString(this.froalaModel).map(ele => ele.src);

        this.loggingService.logMessage('Document created.', false, 'success', null, 5000, 'OK');

        this.savingDoc(false);
      }
    } catch (err) {
      console.error(err);

      // Show an error message depending if the doc is new or already existing.
      this.loggingService.logMessage(`Failed to ${ (this.knowledgeDoc.id) ? 'update' : 'create' } document. Please try again.`, false, 'error', null, 5000, 'OK');
      this.savingDoc(false);
    }
  }

  /**
   * Called by saveDocument(). Contains all logic for creating a new doc and updating our KnowledgeDocument object
   */
  private async createNewDocument(): Promise<KnowledgeReturnObject> {
    try {
      // Take the html string from the WYSIWYG and parse it into a KnowledgeDocument
      let newDoc: KnowledgeDocument = this.knowledgeDoc.createKnowledgeDocumentRequest(this.froalaModel);

      let knowledgeUpdated: KnowledgeReturnObject;
      knowledgeUpdated = await this.knowledgeService.addUpdateKnowledge(newDoc);

      // add/update failed, but call was a 200
      if (!knowledgeUpdated.success) {
        // jump to the catch block and show an error
        throw new Error(knowledgeUpdated.message);
      }

      this.knowledgeDoc.updateId = knowledgeUpdated.additional.docId;
      this.knowledgeDoc.updateVersionNumber = knowledgeUpdated.additional.versionNumber;

      // Doc has been updated, update the editor with the doc and the new ids
      this.froalaModel = this.knowledgeDoc.getHTMLViewString();

      // Run if there were files pending to be uploaded
      if (this.filesToUpload.length > 0) {
        // We must upload the images and update our editor HTML again and re-save the doc
        this.froalaModel = await this.tryUploadImagesAndUpdateEditor(this.knowledgeDoc.id);

        // If there are wiki image urls or files to upload then re-update our document
        // Update our KnowledgeDocument with the updated froalaModel with the new image urls
        newDoc = this.knowledgeDoc.createKnowledgeDocumentRequest(this.froalaModel);
        // Update our doc again server side
        knowledgeUpdated = await this.knowledgeService.addUpdateKnowledge(newDoc);
      }

      const newVersionNumber: number = knowledgeUpdated.additional.versionNumber;

      this.knowledgeDoc.updateVersionNumber = newVersionNumber;
      this.versions = [newVersionNumber];

      // if this was a publish version, also add version number to published array
      if (!this.knowledgeDoc.draft) {
        this.publishedVersions.unshift(newVersionNumber);
      }

      this.resetUnsavedChanges();

      // Success
      return knowledgeUpdated;
    } catch (err) {
      throw new Error(err);
    }
  }

  /**
   * Called by saveDocument(). Contains all logic for creating a new doc and updating our KnowledgeDocument object
   */
  private async updateExistingDocument(): Promise<KnowledgeReturnObject> {
    try {
      if (this.filesToUpload.length > 0) {
        // We must upload the images and update our editor HTML first.
        this.froalaModel = await this.tryUploadImagesAndUpdateEditor(this.knowledgeDoc.id);
      }

      // Take the html string from the WYSIWYG and parse it into a KnowledgeDocument
      const updatedDoc = this.knowledgeDoc.createKnowledgeDocumentRequest(this.froalaModel);

      // Update the doc
      const knowledgeUpdated: KnowledgeReturnObject = await this.knowledgeService.addUpdateKnowledge(updatedDoc);

      // add/update failed, but call was a 200
      if (!knowledgeUpdated.success) {
        // jump to the catch block and show an error
        throw new Error(knowledgeUpdated.message);
      }

      // Update the version number for versioning and revisions view
      const newVersionNumber: number = knowledgeUpdated.additional.versionNumber;

      this.knowledgeDoc.updateVersionNumber = newVersionNumber;
      this.versions.unshift(newVersionNumber);

      // if this was a publish version, also add version number to published array
      if (!this.knowledgeDoc.draft) {
        this.publishedVersions.unshift(newVersionNumber);
      }

      // Success
      this.resetUnsavedChanges();

      return knowledgeUpdated;

    } catch (err) {
      throw new Error(err);
    }
  }

  /**
   * Called when saving a doc, this function will disable any controls on the page and set saving state
   *
   * @param {boolean} saving - current state if saving or not
   */
  savingDoc(saving: boolean, template?: TemplateRef<any>): void {
    if (saving) {
      // Set view to creating or updating state
      this.savingDialog = this.dialog.open(template, {disableClose: true});
      this.isSaving = true;
      this.disableAllInputs(true);
      return;
    }

    // Set view back to default and enable save button
    this.disableAllInputs(false);

    this.initialFroalaModel = this.froalaModel;

    this.resetUnsavedChanges();

    this.isSaving = false;

    if (this.savingDialog) {
      this.savingDialog.close();
      this.savingDialog = null;
    }
  }

  /**
   * Pass in a boolean to disable or enable all page inputs
   *
   * @param {boolean} disable - to freeze or unfreeze the input fields and text editor
   */
  disableAllInputs(disable: boolean) {
    if (disable) {
      // disable editor via instance
      this.editorControls$.subscribe(e => e.getEditor().edit.off());
      // Disable form
      this.docForm.disable();
      return;
    }

    // enable editor via instance
    this.editorControls$.subscribe(e => {
      if (e && e.getEditor) {
        e.getEditor().edit.on();
      }
    });
    // Enable form
    this.docForm.enable();
  }

  /**
   * Opens a find and replace dialog component and passed the HTML string into the component for further processing
   */
  showFindAndReplaceDialog = (): void => {
    // get our current html data
    this.editorControls$.subscribe((e) => {
      const dialogRef = this.dialog.open(KnowledgeFindAndReplaceDialogComponent, {
        data: { html: e.getEditor().html.get(true) }
      });

      dialogRef.beforeClosed().subscribe((res) => {
        if (res) {
          this.froalaModel = res;
        }
      });
    });

  }

  /**
   * Opens a knowledge document search dialog component
   */
  showDocumentSearchDialog = (): void => {
    // get our current html data
    this.editorControls$.subscribe((e) => {
      const editorInstance = e.getEditor();

      // save where the cursor is, to restore after.
      editorInstance.selection.save();

      const selectedText = editorInstance.selection.text();

      const dialogRef = this.dialog.open(KnowledgeDocumentSearchComponent, {
        data: { selection: selectedText },
        minWidth: '70vw',
        maxWidth: '70vw',
        minHeight: '80vh',
        maxHeight: '80vh'
      });
      dialogRef.afterClosed().subscribe((res) => {
        // restore place in editor
        editorInstance.selection.restore();

        if (res) {
          editorInstance.link.insert(res.href, res.text);
        }
      });
    });
  }

  /**
   * Opens a knowledge document section selector dialog component
   */
   showDocumentSectionDialog = (): void => {
    // ensure the document already exists and sections exist
    if (!this.docResult) {
      alert('The Knowledge document must be saved once before creating links to other sections');
      return;
    }

    // get our current html data
    this.editorControls$.subscribe((e) => {
      const editorInstance = e.getEditor();

      // save where the cursor is, to restore after.
      editorInstance.selection.save();

      const selectedText = editorInstance.selection.text();

      const dialogRef = this.dialog.open(KnowledgeDocumentSectionComponent, {
        data: {
          documentModel: this.knowledgeDoc,
          document: this.docResult.additional.knowledgeDocument,
          selection: selectedText
        },
        minWidth: '70vw',
        maxWidth: '70vw',
        minHeight: '80vh',
        maxHeight: '80vh'
      });
      dialogRef.afterClosed().subscribe((res) => {
        // restore place in editor
        editorInstance.selection.restore();

        if (res) {
          editorInstance.link.insert(res.href, res.text, {'data-knowledge-link': 'section'});
        }
      });
    });
  }

  /**
   * Failed to get a doc we assumed existed, set the state to create document.
   */
  handleDocRetrievalError() {
    this.loggingService.logMessage('Failed to retrieve document.', false, 'error', null, 5000, 'OK');

    // Failed to retrieve an existing doc
    this.initModeCreateDoc();
  }

  /**
   * This function is fired every time a file is added to the editor.
   * Creates an object with the necessary data we need to later create a file upload.
   *
   * @param {any} htmlEle - html element (image)
   * @param {File|Blob} data - file or blob of thing placed into editor
   */
  prepareFileForUpload(htmlEle: any, data: File | Blob): void {
    let fileName = '';

    // Check if file has name (if it's File or Blob)
    if (data instanceof File) {
      fileName = `${Date.now()}_${data.name}`;
    } else {
      fileName = `${Date.now()}_${Math.floor(Math.random() * 99999)}`;
    }

    const imageData = {
      elementId: htmlEle.getAttribute('id'),
      fileName,
      path: null,
      data
    };

    this.filesToUpload.push(imageData);
  }

  /**
   * If there are files in our filesToUpload array, run this function which will get the current html in our froala instance,
   * and attempt to store the images in the correct place on firestore storage. Once done, we return the results of all our
   * file uploads. We then take the results and map them back to the files we wanted to upload. We check the dom for the
   * <img> elements and replace the src attr with our new URL from firebase storage. The function then returns the updated HTML.
   *
   * @param {string} docId - id of document
   */
  async tryUploadImagesAndUpdateEditor(docId: string): Promise<string> {
    let editorHtml: string;
    const editorInstance = await this.editorControls$.pipe(take(1)).toPromise();
    editorHtml = editorInstance.getEditor().html.get(true);

    // Path we will store it in
    const path = `Knowledge/${this.knowledgeDoc.groupPublicKey}/${docId}`;

    // Parse our current HTML document so we can change the src of the image we just uploaded.
    const tempDOM = new DOMParser().parseFromString(editorHtml, 'text/html');

    const files = this.filesToUpload.map((item) => {
      return {
        data: item.data,
        path: `${path}/${item.fileName}`
      };
    });

    try {
      const results = await this.storage.startUploads(files);

      for (let i = 0; i < results.length; i++) {
        // make sure upload was successful
        if (!results[i].success) {
          continue;
        }
        const imgElement = (<HTMLImageElement>tempDOM.getElementById(this.filesToUpload[i].elementId));
        imgElement.src = results[i].downloadUrl;
        imgElement.removeAttribute('id');
      }

      // files upload successfully, clear upload queue
      this.filesToUpload = [];

      return tempDOM.body.innerHTML;

    } catch (err) {
      throw new Error(err);
    }
  }

  /**
   * Once an image element is updated, remove it from our filesToUpload and re-set our filesToUpload array to a new
   * array without the image we removed.
   *
   * @param {string} elementId - attr 'id' of the DOM element
   */
  removeFileFromPendingUpload(elementId: string): void {
    this.filesToUpload = this.filesToUpload.filter(item => item.elementId !== elementId);
  }

  /**
   * Does some calculations based on the current document version and the search result to determine what version
   * the user is viewing, what versions exist and what version is published.
   */
  showVersions(): void {
    const versionDialogRef = this.dialog.open(KnowledgeRevisionsComponent, {
      data: {
        currentVersion: this.knowledgeDoc.versionNumber,
        groupPublicKey: this.knowledgeDoc.groupPublicKey,
        id: this.knowledgeDoc.id,
        docHtml: this.froalaModel,
        allVersions: this.versions,
        publishedVersions: this.publishedVersions
      },
      minWidth: '80vw',
      maxWidth: '80vw'
    });

    versionDialogRef.beforeClosed().subscribe(
      (document) => {
        if (document) {
          this.loadDocumentVersion(document);
        }
      }
    );
  }

  /**
   * Takes a document response and sets the appropriate component information
   */
  setDocumentProperties(document: KnowledgeDocument, fromLastState?: boolean): void {
    if (document) {
      this.knowledgeDoc = new KnowledgeModel(document);
    }

    this.setknowledgeGroups();

    // Format the knowledge doc
    // Set formGroup values to match doc since the doc exists
    this.docForm.patchValue({
      name: this.knowledgeDoc.name,
      draft: this.knowledgeDoc.draft,
      group: this.knowledgeDoc.groupPublicKey,
      revisionNote: this.knowledgeDoc.revisionNote
    });

    // check if document is in a current find and replace queue
    this.setFindAndReplace(this.knowledgeDoc.id);

    // Set froala model
    this.froalaModel = this.getInitialEditorHTML();

    // Track the initial html string to compare for changes
    this.initialFroalaModel = this.froalaModel;

    // If there is a find and replace term that is focused on load, scroll to it on load.
    if (this.findAndReplaceOccurences && this.findAndReplaceOccurences[0]) {
      setTimeout(() => {
        this.findAndReplaceFocus(this.findAndReplaceOccurences[0].elementId);
      }, 150);
    }

    this.resetUnsavedChanges();

    // Save a list of any current images existing in the doc
    this.preExistingImages = this.knowledgeDoc.getAllDocumentImageSrc(this.froalaModel);

    // Show content
    this.loadingDoc = false;

    // We have the doc, initialize the editor
    this.editorControls$.subscribe(editor => {
      editor.initialize();
    });
  }

  /**
   * Load a different version of the document into the page. This function is run when the version dialog returns a new doc after close.
   *
   * @param {KnowledgeDocument} doc - document object
   */
  async loadDocumentVersion(doc: KnowledgeDocument): Promise<void> {

    // Trigger template to show loader
    this.loadingDifferentVersion = true;

    // Update current doc with data from this version.
    this.setDocumentProperties(doc);

    this.loadingDifferentVersion = false;
    this.showOutOfDateWarning = true;
  }

  /**
   * Reset the unsaved changes observable to false.
   */
  private resetUnsavedChanges(): void {
    this.unsavedChanges.next(false);
  }

  /**
   * navigates to the newly created/updated document in the view component.
   */
  viewDocument(): void {
    this.router.navigateByUrl('/').then(() => {
      // once route has changed, execute announcement.
      const announcement: AnnounceKnowledgeShow = {
        docId: this.knowledgeDoc.id,
        docGroup: this.knowledgeDoc.groupPublicKey,
        docVersion: this.knowledgeDoc.versionNumber,
        promptForFeedback: false
      };
      this.chatKnowledgeService.announceKnowledgeShow(announcement);
    });
  }

  /**
   * Triggers the feedback pane for this document
   */
  showFeedback(): void {
    this.knowledgeFeedbackService.showFeedbackPanel();
  }

  getInitialEditorHTML(): string {
    let documentHtml = this.knowledgeDoc.getHTMLEditString();

    if (documentHtml !== '') {
      const findAndReplace = this.findAndReplaceService.getDocumentStatus(this.knowledgeDoc.id);
      if (findAndReplace && findAndReplace.current) {
        this.highlightTermInDocument( this.findAndReplaceForm.get('find').value );
        this.goToInitialFoundTerm();
      }
    }

    return this.knowledgeDoc.getHTMLEditString();
  }

  highlightTermInDocument(term: string): void {
    const editorElement = this.froalaInstance.el;

    // add marks to html string.
    // parse html string into a dom element
    if (this.findAndReplaceMarkInstance) {
      this.findAndReplaceMarkInstance.unmark();
    }

    this.findAndReplaceMarkInstance = new Mark(editorElement);

    // clear any old marks
    if (this.findAndReplaceOccurences) {
      this.findAndReplaceOccurences = [];
    }

    // accuracy: 'exactly',
    this.findAndReplaceMarkInstance.mark(term, {
      caseSensitive: this.findAndReplaceForm.get('matchCase').value,
      separateWordSearch: false,
      className: 'marked-item',
      each: (node: HTMLElement) => {
        // assign a unique ID to each element, so we can cross reference them from one DOM to another.
        if (!this.findAndReplaceOccurences) {
          this.findAndReplaceOccurences = [];
        }

        const randomId = `${this.knowledgeDoc.id}_${Math.floor(Math.random() * 99999999)}`;
        node.id = randomId;
        this.findAndReplaceOccurences.push({
          element: node,
          elementId: randomId
        });
      },
      noMatch: () => {
        this.findAndReplaceOccurences = [];
      }
    });
  }

  goToInitialFoundTerm() {
    if (this.findAndReplaceOccurences && this.findAndReplaceOccurences.length > 0) {
      document.getElementById(this.findAndReplaceOccurences[0].elementId).classList.add('mark-highlighted');
      this.findAndReplaceIndex = 0;

      setTimeout(() => {
        this.findAndReplaceFocus(this.findAndReplaceOccurences[0].elementId);
      }, 200)
    }
  }

  /**
   * Triggers the opening of a delete/restore knowledge document dialog.
   */
  deleteKnowledgeDocument(): void {
    const dialogData = {
      groupPublicKey: this.knowledgeDoc.groupPublicKey,
      docId: this.knowledgeDoc.id,
      docName: this.knowledgeDoc.name,
      isDeleted: this.isDeleted
    };
    const dialog = this.dialog.open(KnowledgeDeleteComponent, {data: dialogData});
    dialog.afterClosed().subscribe(
      (success: boolean) => {
        // determine if a restore happened
        if (this.isDeleted && success) {
          this.isDeleted = false;
          // ensure we don't go down to the next IF block.
          return;
        }

        // determine if a delete happened
        if (!this.isDeleted && success) {
          this.isDeleted = true;
          return;
        }
      }
    );
  }

  /**
   * Sets an html element as highlighted and tracks the element in an array.
   */
  setHtmlElementAsHighlighted(element: HTMLElement): void {
    element.style.backgroundColor = '#ffd78e';
    this.highlightedHtmlElements.push(element);
  }

  /**
   * Resets all the highlighted elements background color and then destroy array with references.
   */
  clearHighlightedHtmlElements(): void {
    if (!this.highlightedHtmlElements.length) {
      return;
    }

    this.highlightedHtmlElements.forEach((e) => {
      e.style.backgroundColor = null;
    });

    // all done, now destroy array
    this.highlightedHtmlElements = [];
  }

  /**
   * Checks the initial load string against what is currently in the editor.
   */
  private isEditorContentChanged(newEditorContents: string): boolean {
    return this.initialFroalaModel !== newEditorContents;
  }

  /**
   * 
   * @param direction can be next or prev based on the index and occurences array
   * @param delayScroll delays the scroll slightly, this is to have the view appear to update before scrolling happens
   * @returns nothin'
   */
  findAndReplaceDirection(direction: 'next'|'prev', delayScroll = false): void {
    // check if there are no match occurences
    if (!this.findAndReplaceOccurences || !this.findAndReplaceOccurences.length) {
      // no more matches but make sure editor is up to date
      this.froalaModel = this.froalaInstance.el.innerHTML;
      return;
    }

    // sometimes index can be -1, so ensure this item exists.
    if (this.findAndReplaceOccurences[this.findAndReplaceIndex]) {
      document.getElementById(this.findAndReplaceOccurences[this.findAndReplaceIndex].elementId).classList.remove('mark-highlighted');
    }

    if (direction === 'next') {
      if (this.findAndReplaceIndex === this.findAndReplaceOccurences.length - 1) {
        // end of matches, go to beginning
        this.findAndReplaceIndex = 0;
      } else {
        // go forwards through matches
        this.findAndReplaceIndex = this.findAndReplaceIndex + 1;
      }
    }

    if (direction === 'prev') {
      if (this.findAndReplaceIndex === 0) {
        // beginning of matches, go to end
        this.findAndReplaceIndex = this.findAndReplaceOccurences.length - 1;
      } else {
        // go backwards through matches
        this.findAndReplaceIndex = this.findAndReplaceIndex - 1;
      }
    }

    let hasToScroll: boolean;
    setTimeout(() => {
      hasToScroll = this.findAndReplaceFocus(this.findAndReplaceOccurences[this.findAndReplaceIndex].elementId);
    }, delayScroll ? 200 : 0)
    
    if (hasToScroll) {
      setTimeout(() => {
        document.getElementById(this.findAndReplaceOccurences[this.findAndReplaceIndex].elementId).classList.add('mark-highlighted');
      }, 750);
    } else {
      document.getElementById(this.findAndReplaceOccurences[this.findAndReplaceIndex].elementId).classList.add('mark-highlighted');
    }
  }

  /**
   * 
   * @param ele 
   * @returns 
   */
  findAndReplaceFocus(elementId: string): boolean {
    // get our scrollable container from the dom
    const scrollContainerQuery = document.getElementsByClassName('scrollable-container');
    if (!scrollContainerQuery.length) {
      // no scroll container was found...
      return;
    }
    
    const scrollContainer = scrollContainerQuery[0];
    // check coordinates of the ele we want to scroll to
    const scrollContainerCoords = scrollContainer.getBoundingClientRect();
    const itemCoords = document.getElementById(elementId).getBoundingClientRect();

    if (itemCoords.top > scrollContainerCoords.top + Math.floor(scrollContainerCoords.height * 0.25) && itemCoords.bottom < scrollContainerCoords.bottom - Math.floor(scrollContainerCoords.height * 0.25)) {
      return false;
    } else {
      // scroll to view range.
      scrollContainer.scrollBy({
        left: 0,
        top: (Math.floor(itemCoords.y) - window.innerHeight) + itemCoords.height + scrollContainerCoords.height / 2,
        behavior: 'smooth'
      })
      return true;
    }
  }

  findAndReplaceAction(elementId: string): void {
    const replacementText = this.findAndReplaceForm.get('replace').value.trim();
    const ele = document.getElementById(elementId);

    this.updateDocumentInnerText(ele, replacementText);
    this.unsavedChanges.next(true);

    const index = this.findAndReplaceOccurences.findIndex((item) => ele.id === item.elementId);
    this.findAndReplaceOccurences.splice(index, 1);

    // determine the new index, we can use these indexes because we are calling `findAndReplaceDirection`
    if (this.findAndReplaceIndex === 0) {
      this.findAndReplaceIndex = -1;
    } else {
      this.findAndReplaceIndex = this.findAndReplaceIndex - 1;
    }

    // go to next occurence
    this.findAndReplaceDirection('next', true);
  }

  findAndReplaceAll(): void {
    if (!this.findAndReplaceOccurences || !this.findAndReplaceOccurences.length) {
      return;
    }

    const replacementText = this.findAndReplaceForm.get('replace').value.trim();
    this.findAndReplaceOccurences.forEach((item) => {
      const ele = document.getElementById(item.elementId);
      this.updateDocumentInnerText(ele, replacementText);
    })
    this.unsavedChanges.next(true);

    this.findAndReplaceOccurences = [];
    this.findAndReplaceIndex = 0;
  }

  private findAndReplaceReset(skipFormReset = false) {
    // remove marks from dom element
    if (this.findAndReplaceMarkInstance) {
      this.findAndReplaceMarkInstance.unmark();
    }
    // reset input form, but when we close the find and replace, we may
    // not want to delete the contents of the form search value, so we skip the reset of the form.
    if (skipFormReset) {
      this.findAndReplaceForm.reset();
    }
    // remove matches of that occurences was initialized
    this.findAndReplaceOccurences = null;
    // reset index to beginning
    this.findAndReplaceIndex = 0;
  }

  openNextDocument(dialogTemplate: TemplateRef<any>) {
    // close current document
    const unsavedChanges = this.unsavedChanges.getValue();

    if (unsavedChanges) {
      // prompt
      this.dialog.open(dialogTemplate).afterClosed().subscribe((skipSaving) => {
        if (skipSaving) {
          this.multiViewService.updateTabsAndSaveToLastState(Routes.Knowledge, 'Remove', null, this.tabIndex);
          this.findAndReplaceService.openNextDocument();
        }
      })
      return;
    }

    // default to close and go to next item in queue
    this.multiViewService.updateTabsAndSaveToLastState(Routes.Knowledge, 'Remove', null, this.tabIndex);
    this.findAndReplaceService.openNextDocument();
  }

  private updateDocumentInnerText(ele: HTMLElement, newText: string) {
    // manually save the previous html in editor undo stack
    // this is also updating the html in the editor..
    const docFrag = document.createDocumentFragment();
    ele.innerText = newText;
    while (ele.firstChild) {
      const child = ele.removeChild(ele.firstChild);
      docFrag.appendChild(child);
    }
    ele.parentNode.replaceChild(docFrag, ele);
  }

  toggleFindAndReplace(): void {
    if (this.findAndReplaceVisible) {
      this.findAndReplaceReset(true);
      this.findAndReplaceVisible = false;
      return;
    }
    this.findAndReplaceVisible = true;

    this.setFindAndReplace(this.knowledgeDoc.id);

    // check if a term needs highlighting
    const findTerm = this.findAndReplaceForm.get('find').value; 
    if (!findTerm) {
      return;
    }
    this.highlightTermInDocument(findTerm);
  }

  setFindAndReplace(docId: string): void {
    const findAndReplace = this.findAndReplaceService.getDocumentStatus(docId);
    if (findAndReplace && findAndReplace.current) {
      this.findAndReplaceVisible = true;
      this.findAndReplaceStatus = findAndReplace;
      // populate form values
      this.findAndReplaceForm.get('find').setValue(findAndReplace.current.find, {emitEvent: false});
      this.findAndReplaceForm.get('matchCase').setValue(findAndReplace.current.caseSensitive);
    }
  }

  froalaChange(html: string): void {
    if (!html) { return; }
    this.editorContentChanged.next( this.isEditorContentChanged(html) );
  }

}
