import * as DOMPurify from 'dompurify';

/**
 * Used to sanitize HTML coming into the app before it's parsed and executed in the view of the application
 */
export class HtmlSanitization {
  constructor() {
    DOMPurify.addHook('afterSanitizeAttributes', (node) => {
      // set all elements with a target to target=_blank
      if ('target' in node) {
        node.setAttribute('target', '_blank');
        node.setAttribute('rel', 'noopener');
      }
    });
  }
  /**
   * Accepts an html string and returns a sanitized html string
   *
   * @param htmlString
   */
  public clean(htmlString: string): string {
    const sanitizedHtmlString = DOMPurify.sanitize(htmlString);
    // if (htmlString.length !== sanitizedHtmlString.length) {
    //   console.log(htmlString);
    //   console.log(sanitizedHtmlString);
    //   console.log(`-----`);
    // }
    return sanitizedHtmlString;
  }


}
