<h1 mat-dialog-title>{{headerText}}</h1>
<mat-progress-bar *ngIf="loading" color="primary" mode="indeterminate">
</mat-progress-bar>
<div mat-dialog-content>
  <!-- Unarchive Document -->
  <ng-container *ngIf="data.isDeleted">
    <p>Are you sure you'd like to restore <strong>{{data.docName}}</strong>?</p>
    <p>This document will become publicly available again to search and access when the next training cycle is run.</p>
  </ng-container>
  <!-- Archive Document -->
  <ng-container *ngIf="!data.isDeleted">
    <p>Are you sure you'd like to archive <strong>{{data.docName}}</strong>?</p>
    <p>This document will no longer be used in future training cycles. Searchable sections of this document will be removed during the next training cycle.</p>
  </ng-container>
</div>
<div mat-dialog-actions fxLayoutAlign="end" class="dialog-actions">
  <button mat-button (click)="cancel()" [disabled]="loading">Cancel</button>
  <button mat-flat-button color="primary" (click)="confirmAction()" cdkFocusInitial [disabled]="loading">{{buttonText}}</button>
</div>
