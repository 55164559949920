import { KnowledgeUtils } from '@eva-model/knowledgeUtils';
import { FirestoreService } from '@eva-services/firestore/firestore.service';
import { KnowledgeService } from '@eva-services/knowledge/group/knowledge.service';
import { KnowledgeDocument, KnowledgeDocumentSectionFlat, OpenKnowledgeDocument,
  KnowledgeDocumentTableOfContents } from '@eva-model/knowledge/knowledge';
import { Observable } from 'rxjs';
import { KnowledgeSectionComponent } from '@eva-ui/admin-overview/knowledge/knowledge-section/knowledge-section.component';
import { KnowledgeModel } from '@eva-model/knowledge';
import { Component, OnInit, ViewChildren, QueryList, Renderer2, AfterViewInit, ElementRef,
  ViewContainerRef, Input, Output, EventEmitter, OnDestroy, HostListener, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, take, filter, map, debounce, delay } from 'rxjs/operators';
import { of, BehaviorSubject, fromEvent, Subscription, timer } from 'rxjs';
import { KnowledgeReturnObject } from '@eva-model/return-objects/returnObjects';
import { LoggingService } from '@eva-core/logging.service';
import { ChatKnowledgeService } from '@eva-services/chat/knowledge/chat-knowledge.service';
import { KnowledgeDocumentFeedbackService } from '@eva-services/knowledge/feedback/knowledge-document-feedback.service';
import { KnowledgeFeedbackPromptComponent } from '../knowledge-feedback-prompt/knowledge-feedback-prompt.component';
import { GroupProviderService } from '@eva-core/group-provider.service';
import { KnowledgeMultiService } from '@eva-services/knowledge/knowledge-multi/knowledge-multi.service';
import { MultiViewService } from '@eva-services/home/multi-view/multi-view.service';
import { KnowledgeCreateComponent } from '../knowledge-create/knowledge-create.component';
import { DatePipe } from '@angular/common';
import { LaunchPadService } from '@eva-services/nav/launch-pad.service';
import { LaunchPadEntityType } from '@eva-model/menu/menu';
import { Routes } from '@eva-model/menu/defaults/mainMenu';
import { KnowledgeDiffComponent } from '../knowledge-diff/knowledge-diff.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-knowledge',
  templateUrl: './knowledge.component.html',
  styleUrls: ['./knowledge.component.scss']
})
export class KnowledgeComponent implements OnInit, AfterViewInit, OnDestroy {
  uniqueTabId: string;

  @Input()
  set data(knowledge: OpenKnowledgeDocument) {
    this.isComponentView = true;

    this.id = knowledge.docId;
    this.groupPk = knowledge.docGroup;
    this.version = knowledge.docVersion;
    this.modelVersion = knowledge.modelVersion;
    this.highlightSectionId = knowledge.sectionId;
    this.promptForFeedback = knowledge.promptForFeedback;

    if (knowledge.hasOwnProperty('viewKey')) {
      this.viewKey = knowledge.viewKey;
    }

    if (knowledge.hasOwnProperty('published')) {
      this.published = knowledge.published;
    }

    if (knowledge.document) {
      // already have doc, set loading to false.
      this.knowledgeDoc = knowledge.document;
    }
  }

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private knowledgeService: KnowledgeService,
              private firestoreService: FirestoreService,
              private renderer: Renderer2,
              private loggingService: LoggingService,
              private chatKnowledgeService: ChatKnowledgeService,
              private knowledgeFeedbackDocumentService: KnowledgeDocumentFeedbackService,
              private groupProviderService: GroupProviderService,
              private elementRef: ElementRef,
              private multiViewService: MultiViewService,
              private datePipe: DatePipe,
              private launchPad: LaunchPadService,
              private dialog: MatDialog,
              @Optional() private knowledgeMultiService?: KnowledgeMultiService) {
                this.routeFragment$ = this.activatedRoute.fragment;
                this.componentSubs.add(
                  this.multiViewService.closeTab$.pipe(
                    filter((closeTab) => closeTab && closeTab.entityType === 'Knowledge'),
                    filter((closeTab) => this.uniqueTabId === closeTab.entityId),
                    filter((closeTab) => this.tabIndex === closeTab.tabIndex)
                  ).subscribe(closeTab => {
                    setTimeout(() => {
                      closeTab.closeSubject.next(true);
                    });
                  })
                );
              }
  public isLoading = true;
  public isComponentView = false; // set when this component is injected into the view
  public promptForFeedback = false; // set when knowledge is announce to show
  public hideFeedbackPrompt = false; // set to true if feedback prompt emits a close event

  public id: string; // doc id
  public groupPk: string; // doc group
  public version: number; // doc version
  public modelVersion: string; // set if knowledge initialized from an announcement
  public published = true; // when sending the request, whether to get the published version
  public highlightSectionId: string; // section to highlight, set when loading component as view
  public viewKey: string; // key to include to bypass if the document has been archived/removed but should still be viewed
  public dialogflowResponse: any;

  public knowledgeDoc: KnowledgeDocument = null;
  public allSections: KnowledgeDocumentSectionFlat[];
  public knowledgeHtml: string = null; // parsed from knowledge doc
  public tableOfContents: KnowledgeDocumentTableOfContents;
  public updatedBy: string = null; // first we get a pk then get the name of that user.
  public versions: number[] = []; // all versions of the doc, if doc doesn't exist then array is empty.
  public isArchived: boolean = false; // if the document "isDeleted"

  private routeFragment$: Observable<string>;
  private sectionChildren$: BehaviorSubject<KnowledgeSectionComponent[]> = new BehaviorSubject([]);

  // Track our currently highlighted comp, so if we highlight a new one, disable the highlight on this one.
  private highlightedElementRef: ElementRef;
  public selectedFeedbackSections$: Observable<KnowledgeDocumentSectionFlat[]>;

  private parentScrollSub: Subscription;
  private sectionFocusSub: Subscription;
  private routeParamsSub: Subscription;
  tabIndex: number;
  // contains all active subscriptions for this component.
  componentSubs = new Subscription();

  @ViewChildren(KnowledgeSectionComponent) sectionsCmp: QueryList<KnowledgeSectionComponent>;
  @ViewChildren(KnowledgeFeedbackPromptComponent, { read: ViewContainerRef }) feedbackPrompt: QueryList<ViewContainerRef>;

  @Output() closeClicked = new EventEmitter<boolean>();

  /**
   * Check all clicks inside the component for a click on an anchor element that is a link to a knowledge document.
   * If clicked, intercept the click, do not take action but parse the query params and announce a document.
   */
  @HostListener('click', ['$event'])
  handleClick(e: any) {
    if (e.target && e.target.tagName === 'A') {
      // Link to another knowledge document
      if (KnowledgeUtils.isAnchorHrefKnowledgeLink(e.target.href)) {
        if (!this.isComponentView) {
          this.chatKnowledgeService.parseUrlStringAndNavigate(e.target.href);
        } else {
          this.chatKnowledgeService.parseUrlStringAndAnnounce(e.target.href);
        }

        // prevent the anchor link normal behavior.
        e.preventDefault();
      }

      // Link to a knowledge section
      if (KnowledgeUtils.isAnchorHrefSectionLink(e.target)) {
        const parsedSectionId = e.target.getAttribute('href');
        const sectionId = parsedSectionId.replace('#', '');
        this.chatKnowledgeService.focusSectionId(this.knowledgeDoc.id, sectionId, false);

        // prevent the anchor link normal behavior.
        e.preventDefault();
      }
    }
  }

  ngOnInit() {
    // This component can be loaded into a view or be navigated to. If this component has been navigated to by the url, grab
    // the data out of the url, navigate home and then do an announcement to show this document.
    if (!this.isComponentView) {
      this.routeParamsSub = this.activatedRoute.params.subscribe((params) => {
        this.id = params['id'];
        this.groupPk = params['groupPk'];
        this.viewKey = params['viewKey'];

        const versionParam = params['version'];
        if (versionParam === '0') {
          // set version to null, and by default will get the latest published version.
          this.version = null;
        } else {
          this.version = Number(versionParam);
        }

        // listen for focus of a section
        this.sectionFocusSub = this.chatKnowledgeService.listenForSectionFocusByDocId(this.id).subscribe((section) => {
          if (section.highlight) {
            this.setSectionHighlightedAndScroll(section.sectionId);
          } else {
            this.clearHighlightedSection();
            this.scrollToSection(section.sectionId);
          }
        });

        this.getKnowledge();
      });
    }

    if (this.isComponentView) {
      if (this.multiViewService.tabs[Routes.Knowledge] && this.multiViewService.tabs[Routes.Knowledge][this.tabIndex]
        && (this.multiViewService.tabs[Routes.Knowledge][this.tabIndex].tabName === 'Loading...'
        || this.multiViewService.tabs[Routes.Knowledge][this.tabIndex].tabName === (document as any).name)) {
          if (this.multiViewService.tabs[Routes.Knowledge][this.tabIndex].additionalLocalInstanceData &&
              this.multiViewService.tabs[Routes.Knowledge][this.tabIndex].additionalLocalInstanceData.dialogflowResponse) {
                // eslint-disable-next-line max-len
                this.dialogflowResponse = this.multiViewService.tabs[Routes.Knowledge][this.tabIndex].additionalLocalInstanceData.dialogflowResponse;
              }
      }

      // listen for focus of a section on init. Wait for a delay then highlight.
      this.sectionFocusSub = this.chatKnowledgeService.listenForSectionFocusByDocId(this.id).pipe(
        filter(focus => focus.docId === this.id),
        delay(300)
      ).subscribe((section) => {
        this.clearHighlightedSection();
        if (section.highlight) {
          this.setSectionHighlightedAndScroll(section.sectionId);
        } else {
          this.scrollToSection(section.sectionId);
        }
      });

      // Retrieve and/or Set the Knowledge Document
      if (this.knowledgeDoc) {
        // skip GET and go to format what we have set
        this.formatKnowledge(this.knowledgeDoc);
      } else {
        this.getKnowledge();
      }
    }
  }

  ngAfterViewInit() {
    if (this.sectionsCmp.length) {
      this.sectionChildren$.next(this.sectionsCmp.toArray());
      return;
    }

    this.sectionsCmp.changes.subscribe((data) => {
      this.sectionChildren$.next(data);
    });
  }

  ngOnDestroy() {
    if (this.parentScrollSub) {
      this.parentScrollSub.unsubscribe();
    }
    if (this.sectionFocusSub) {
      this.sectionFocusSub.unsubscribe();
    }

    if (this.componentSubs) {
      this.componentSubs.unsubscribe();
    }
  }

  /**
   * Makes call to get document based on parameters in the url.
   * If there's a success, hands the data off to be further formatted
   * Handles errors.
   */
  async getKnowledge(): Promise<any> {
    this.isLoading = true;

    try {
      let docResponse: KnowledgeReturnObject;
      // Get the last published version if there is a published version, else get the latest draft
      if (this.version) {
        docResponse = await this.knowledgeService.getKnowledgeByVersion(this.groupPk, this.id, String(this.version), this.viewKey);
      } else {
        docResponse = await this.knowledgeService.getNewestKnowledge(this.groupPk, this.id, this.published, this.viewKey);
      }

      if (!docResponse.success) {
        this.handleError('Failed to get document.');
        if (this.multiViewService.tabs[Routes.Knowledge] && this.multiViewService.tabs[Routes.Knowledge][this.tabIndex]) {
          this.multiViewService.updateTabsAndSaveToLastState(Routes.Knowledge, 'Update', {tabName: 'Error'}, this.tabIndex);
        }
        return;
      }

      this.isArchived = docResponse.additional.isDeleted;

      // Format the knowledge
      this.formatKnowledge(docResponse.additional.knowledgeDocument);

      // this.knowledgeMultiService.updateLoadingStatus(this.id, false, docResponse.additional.knowledgeDocument.name);

      // Get the knowledge author
      const updatedBy = docResponse.additional.knowledgeDocument.updatedBy;
      this.firestoreService.colWithIds$('UserDirectory', ref => ref.where('publicKey', '==', updatedBy)).pipe(
        take(1)
      ).subscribe((users) => {
        if (users && users.length > 0) {
          this.updatedBy = users[0].preferredName;
        }
      }, () => {
        // silently fail to get author directory doc, not really an issue to the end user
      });
    } catch (err) {
      this.handleError('Failed to get document.');
      if (this.multiViewService.tabs[Routes.Knowledge] && this.multiViewService.tabs[Routes.Knowledge][this.tabIndex]) {
        this.multiViewService.updateTabsAndSaveToLastState(Routes.Knowledge, 'Update', {tabName: 'Error'}, this.tabIndex);
      }
      console.error('failed to get knowledge', err);
    }
  }

  async getVersion(version: number): Promise<any> {
    this.isLoading = true;

    try {
      let docResponse: KnowledgeReturnObject;
      // Get the last published version if there is a published version, else get the latest draft
      docResponse = await this.knowledgeService.getKnowledgeByVersion(this.groupPk, this.id, String(version), this.viewKey);

      if (!docResponse.success) {
        this.handleError('Failed to get specific document version.');
        if (this.multiViewService.tabs[Routes.Knowledge] && this.multiViewService.tabs[Routes.Knowledge][this.tabIndex]) {
          this.multiViewService.updateTabsAndSaveToLastState(Routes.Knowledge, 'Update', {tabName: 'Error'}, this.tabIndex);
        }
        return;
      }

      // Format the knowledge
      this.formatKnowledge(docResponse.additional.knowledgeDocument);

      // Get the knowledge author
      const updatedBy = docResponse.additional.knowledgeDocument.updatedBy;
      this.firestoreService.colWithIds$('UserDirectory', ref => ref.where('publicKey', '==', updatedBy)).pipe(
        take(1)
      ).subscribe((users) => {
        if (users && users.length > 0) {
          this.updatedBy = users[0].preferredName;
        }
      }, () => {
        // silently fail to get author directory doc, not really an issue to the end user
      });
    } catch (err) {
      this.handleError('Failed to get document.');
      if (this.multiViewService.tabs[Routes.Knowledge] && this.multiViewService.tabs[Routes.Knowledge][this.tabIndex]) {
        this.multiViewService.updateTabsAndSaveToLastState(Routes.Knowledge, 'Update', {tabName: 'Error'}, this.tabIndex);
      }
      console.error('failed to get knowledge', err);
    }
  }

  /**
   * Takes a successful document from a GET response and sets up the rest of document.
   * @param document - knowledge document
   */
  formatKnowledge(document: KnowledgeDocument) {
    const knowledge = new KnowledgeModel(document);

    this.knowledgeHtml = knowledge.getHTMLViewString();
    this.knowledgeDoc = document;
    this.version = knowledge.versionNumber;

    // Update our multi service
    if (this.multiViewService.tabs[Routes.Knowledge] && this.multiViewService.tabs[Routes.Knowledge][this.tabIndex]
      && (this.multiViewService.tabs[Routes.Knowledge][this.tabIndex].tabName === 'Loading...'
      || this.multiViewService.tabs[Routes.Knowledge][this.tabIndex].tabName === document.name)) {
      this.multiViewService.updateTabsAndSaveToLastState(Routes.Knowledge, 'Update', {
        tabName: document.name,
        additionalInstanceData:
          {
            ...this.multiViewService.tabs[Routes.Knowledge][this.tabIndex].additionalInstanceData
          }
        }, this.tabIndex);
    }

    // set name of tab on multi view
    if (this.knowledgeMultiService) {
      this.knowledgeMultiService.updateOpenDocName(this.id, this.knowledgeDoc.name);
    }

    // flatten sections for component rendering
    this.allSections = KnowledgeUtils.getSectionsAsFlatArray(this.knowledgeDoc.sections, this.knowledgeDoc.text);
    this.tableOfContents = knowledge.createTableOfContents(this.knowledgeDoc.id, this.knowledgeDoc.sections);

    this.isLoading = false;

    // Everything is loaded, get my parent container and start watching it's scroller
    this.watchParentScrollEvents();

    // Got knowledge, now check if our requested section id component exists
    if (this.highlightSectionId) {
      setTimeout(() => {
        this.setSectionHighlightedAndScroll(this.highlightSectionId);
      }, 1000);
    }
  }

  /**
   * Searches for the section id in the document. If found, will set it as the highlighted
   * section and then scroll to it. QoL function.
   * @param sectionId id of a section
   */
  async setSectionHighlightedAndScroll(sectionId: string) {
    let sectionExists;
    try {
      sectionExists = await this.getSectionComponent(sectionId);
    } catch (err) {
      console.error(err);
    }

    if (sectionExists) {
      this.highlightSectionId = sectionId;

      // clear any other selections from this document
      this.knowledgeFeedbackDocumentService.clearAllSelectionsByDocument(this.id);

      // set a default selection for feedback
      const sectionData: KnowledgeDocumentSectionFlat = this.allSections.find((section) => section.id === sectionId);
      this.knowledgeFeedbackDocumentService.toggleSelectedSection(this.id, sectionId, sectionData);

      // now highlight and scroll to section in component
      this.setSectionHighlighted(sectionExists.elementRef);
      this.scrollToSection(sectionId);

      // track this section as viewed
      this.knowledgeFeedbackDocumentService.addProvidedAnswer({
        ...sectionData,
        docId: this.id
      });
    }
  }

  /**
   * Queries the page and checks for a component that matches the section id, returns the component or undefined.
   * @param sectionId section id (eg. 14_1)
   */
  private async getSectionComponent(sectionId: string): Promise<KnowledgeSectionComponent> {
    const sectionComponent = this.sectionChildren$.pipe(
      switchMap((sectionComponents: KnowledgeSectionComponent[]) => {
        return of(sectionComponents.find(cmp => sectionId === cmp.sectionId)).pipe(
          filter(a => a !== undefined)
        );
      }),
      take(1)
    ).toPromise();
    return await sectionComponent;
  }

  /**
   * Scrolls to the section if the left pane is showing and highlightSectionId is set.
   * @param targetSectionId - document section id to scroll to (eg. 14_1)
   */
  scrollToSection(targetSectionId: string) {
    // attempting a set timeout to make sure DOM changes before trying to target
    setTimeout(() => {
      // this parent element will exist if the component is being used on home component inside knowledge multi component
      const parentEle = document.querySelector('.mat-tab-body.mat-tab-body-active .mat-tab-body-content');
      if (parentEle) {
        // component view scrolling (component is on template and data being passed into)
        const sectionComponentsIndex = this.sectionsCmp.toArray().findIndex((cmp) => {
          return cmp.sectionId && cmp.sectionId === targetSectionId;
        });

        if (sectionComponentsIndex > -1) {
          const sectionEle = this.sectionsCmp.toArray()[sectionComponentsIndex].elementRef.nativeElement;
          setTimeout(() => {
            sectionEle.scrollIntoView();
          }, 0);

          // apply an indication that this section is the section you're looking for
          // sectionEle.classList.add('knowledge-section-highlight-attention');
          // setTimeout(() => {
          //   sectionEle.classList.remove('knowledge-section-highlight-attention');
          // }, 3000);

          // when scrollIntoView occurs, fix scroll position slightly so the document title is not above the scroll to section
          // find the scrollable-container that exists in dom, this is the tab being shown
          setTimeout(() => {
            let scrollOffset = -92;

            const scrollableContainer = document.getElementsByClassName('scrollable-container')[0];
            const documentHeaderEle = document.getElementsByClassName('document-component-header-container')[0];
            if (documentHeaderEle) {
              scrollOffset = -(documentHeaderEle.getBoundingClientRect().height + 12);
            }

            scrollableContainer.scrollBy(0, scrollOffset);
          }, 100);
        }
      } else {
        // component route scrolling (component was navigated to via route)
        const sectionComponentsIndex = this.sectionsCmp.toArray().findIndex((cmp) => {
          return cmp.sectionId && cmp.sectionId === targetSectionId;
        });

        if (sectionComponentsIndex > -1) {
          this.sectionsCmp.toArray()[sectionComponentsIndex].elementRef.nativeElement.scrollIntoView();
          // when scrollIntoView occurs, fix scroll position slightly so the document title is not above the scroll to section
          window.scrollBy(0, this.calculateSectionScrollOffset());
        }
      }
    }, 0);
  }

  /**
   * There's a few places where errors can occur, so we have one function to capture any failure.
   * - the call to get a doc was successful but the doc doesn't exist
   * - the request failed entirely
   * @param msg message to show
   */
  handleError(msg: string) {
    this.loggingService.logMessage(msg, false, 'error');
    this.isLoading = false;
  }

  /**
   * Applies and removes the appropriate highlighting on a section DOM element.
   */
  setSectionHighlighted(ref: ElementRef) {
    // add highlight to requested section
    this.renderer.addClass(ref.nativeElement, 'section-highlight');

    // Update the highlighted element ref
    this.highlightedElementRef = ref;
  }

  // if there is a ref to a highlighted section, clear it's CSS highlight
  clearHighlightedSection(): void {
    if (this.highlightedElementRef) {
      this.renderer.removeClass(this.highlightedElementRef.nativeElement, 'section-highlight');
      this.highlightedElementRef = null;
    }
  }

  /**
   * Generates a link to the document and copies it to the clipboard.
   * Optionally passing in a section id to add it as a query param
   *
   * @param sectionId section id (eg. 14_1)
   * @param version number - only for drafts
   */
  getDocumentLink(sectionId?: string, version?: number) {
    this.chatKnowledgeService.copyDocumentLinkToClipboard(this.id, this.groupPk, sectionId, version);
  }

  /**
   * Updates the url with the passed in anchor. The fragment is at the end of the url, and the prefix is #
   */
  changeUrlFragment(anchor: string) {
    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        // queryParams: queryParams,
        // queryParamsHandling: "merge", // remove to replace all query params by provided
        fragment: anchor
      });
  }

  /**
   * Controls going to the top of the view. Called by a fixed button on the document page.
   */
  backToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }

  /**
   * Changes the router to show the edit mode for this document
   */
  editDocument(docName: string) {
    this.chatKnowledgeService.announceKnowledgeEdit({
      docGroup: this.knowledgeDoc.groupPublicKey,
      docId: this.knowledgeDoc.id,
      docName: this.knowledgeDoc.name,
      published: !this.knowledgeDoc.draft,
      viewKey: 'bGV0IG1lIGluIHBsZWFzZSE'
    })
  }

  /**
   * Emits an event that the close button was clicked.
   */
  closeComponentView() {
    this.closeClicked.emit(true);
  }

  /**
   * Loops through all child sections and enables the child component {@link KnowledgeSectionComponent} into feedback mode.
   */
  enableSectionSelection() {
    this.sectionsCmp.toArray().forEach((cmp) => {
      cmp.toggleFeedbackMode = true;
    });
  }

  /**
   * Loops through all child sections and disables feedback in all child {@link KnowledgeSectionComponent}
   */
  disableSectionSelection() {
    this.sectionsCmp.toArray().forEach((cmp) => {
      cmp.toggleFeedbackMode = false;
    });
  }

  /**
   * Toggles when a child component {@link KnowledgeSectionComponent} is selected
   *
   * @param componentRef child component reference
   */
  trackSelectedSection(componentRef: KnowledgeSectionComponent) {
    this.knowledgeFeedbackDocumentService.toggleSelectedSection(this.id, componentRef.documentId, componentRef.section);
  }

  /**
   * Creates an observable that emits the group name of the provided group key if found
   *
   * @param groupPublicKey
   */
  createGroupNameObservable(groupPublicKey: string): Observable<string> {
    return this.groupProviderService.groupByPublicKey(groupPublicKey).pipe(
      take(1),
      map(group => group.groupName)
    );
  }

  /**
   * Function to destroy the feedback component. We have to destroy it from the parent because the parent has access to its ComponentRef.
   * cannot destroy a component from inside itself.
   */
  destroyFeedbackPrompt() {
    this.disableSectionSelection();
    this.hideFeedbackPrompt = true;
  }

  /**
   * Because this component is inside a material tabs component, we watch the parent element for scrolling and
   * save the scroll position when switching to and from this component in a tab.
   */
  watchParentScrollEvents() {
    // set timeout or this will be null...
    setTimeout(() => {
      const parent = this.elementRef.nativeElement.parentElement;
      if (parent) {
        this.parentScrollSub = fromEvent(parent, 'scroll').pipe(
          debounce(v => timer(500)),
          map((e: Event) => (e.target as Element).scrollTop)
        ).subscribe((value: number) => {
          this.chatKnowledgeService.saveScrollPosition(this.id, value);
        });
      }
    }, 0);
  }

  /**
   * Fetches a supplied version of the document, replacing the current version being viewed
   */
  public switchVersion(version: number): void {
    // if (version) {
    //   // find the current tab
    //   const currentDocumentTabIndex = this.multiViewService.tabs[Routes.Knowledge].findIndex((tab) => {
    //     if (tab && tab.additionalInstanceData && tab.additionalInstanceData.data && tab.additionalInstanceData.data.docId) {
    //       return tab.additionalInstanceData.data.docId = this.id;
    //     }
    //   });

    //   // close the current tab
    //   if (currentDocumentTabIndex >= 0) {
    //     this.multiViewService.tabs[Routes.Knowledge].splice(currentDocumentTabIndex, 1);
    //   }

    //   // announce and create a new tab
    //   this.chatKnowledgeService.announceKnowledgeShow({
    //     docId: this.id,
    //     docGroup: this.groupPk,
    //     promptForFeedback: false,
    //     docVersion: version,
    //     viewKey: this.viewKey,
    //     published: this.published
    //   });
    // }
    this.getVersion(version);
  }

  printDocument(): void {
    const win = window.open();
    self.focus();
    win.document.open();
    win.document.write(`<h2>${this.knowledgeDoc.name}</h2>`);
    win.document.write(`<h5>Revision Date: ${this.datePipe.transform(this.knowledgeDoc.updatedDate, 'long')}</h5>`);
    win.document.write(this.knowledgeHtml);
    win.document.close();
    win.print();
    win.close();
  }

  showTableOfContents(): void {
    this.chatKnowledgeService.setTableOfContents(this.tableOfContents);
    this.chatKnowledgeService.showTableOfContents();
  }

  // we don't do a dom calc here because it's undependable when component is init
  // (question prompt height=56px) + (document header height=69px) + (slight offset=12px)
  calculateSectionScrollOffset(): number {
    return -350;

    if (this.promptForFeedback && !this.hideFeedbackPrompt) {
      return -137;
    }
    // if no prompt being displayed
    return -81;
  }

  pinToLaunchPad(): void {
    this.launchPad.addCustomItem(LaunchPadEntityType.Knowledge, this.knowledgeDoc.name, {
      docId: this.id,
      docGroup: this.groupPk,
      promptForFeedback: false
    });
  }

  /**
   * Takes the current doc and a version doc, opens a modal and shows a comparison.
   */
   knowledgeCompare(): void {
    if (this.isLoading) {
      // could not show diff, no doc was loaded
      return;
    }

    // const versionDocHtml = new KnowledgeModel(this.currentDoc).getHTMLViewString();
    const versionDocHtml = this.knowledgeHtml;

    this.dialog.open(KnowledgeDiffComponent, {
      data: {
        currentDocHtml: versionDocHtml,
        versionDocHtml: versionDocHtml,
        versionDocTimestamp: this.version,
        docVersions: this.versions,
        groupPublicKey: this.groupPk,
        id: this.id
      },
      minWidth: '85vw'
    });
  }
}
