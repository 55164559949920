
import { Component, OnInit, Input, ViewChild, ElementRef, HostListener, OnDestroy, AfterViewChecked, TemplateRef, Output, AfterViewInit,
  EventEmitter, 
  ContentChild} from '@angular/core';
import { PropertyMapping } from '@eva-model/search';
import { SearchValueModifierService } from '@eva-services/search/search-value-modifier.service';
import { InstantSearchService } from '@eva-services/search/instant-search.service';
import { Observable, Subscription } from 'rxjs';
import { ChatService } from '@eva-services/chat/chat.service';
import { debounceTime } from 'rxjs/operators';
import { MultiViewService } from '@eva-services/home/multi-view/multi-view.service';
import { UserService } from '@eva-services/user/user.service';
import { GenericSearchFilter, GenericSearchFilterType, GenericSearchView } from '@eva-model/generic-search';
import { ColDef, ColGroupDef, ColumnApi, GridApi } from 'ag-grid-community';
import { CustomTooltipComponent } from '@eva-ui/process/process-dashboard/custom-dashboard-tooltip/custom-dashboard-tooltip.component';
import * as moment from 'moment';
import { ActionTemplateRendererComponent } from './action-template/action-template.component';
import { InstantSearchConfig, SearchRequest } from 'angular-instantsearch/instantsearch/instantsearch';
import { AlgoliaTokenType } from '@eva-model/search/search';
import { SearchAuthService } from '@eva-core/search-auth-service';
import * as algoliasearch from 'algoliasearch';
import { environment } from '@environments/environment';

// ms to end of day
const MS_IN_DAY = 86399999;

@Component({
  selector: 'app-generic-search',
  templateUrl: './generic-search.component.html',
  styleUrls: ['./generic-search.component.scss']
})
export class GenericSearchComponent implements OnInit, OnDestroy, AfterViewInit {

  _searchTitle: string;                                       // Title of the search
  _searchSubTitle: string;                                    // Sub-title of the search
  _showSearchTitle = false;                                   // Toggle to show/hide the title of the search
  _showSearchSubTitle = false;                                // Toggle to show/hide the sub-title of the search
  _searchConfig: InstantSearchConfig;                         // config for the algolia instance
  _indexName: string;
  _tokenType: AlgoliaTokenType;
  _searchParams = {};
  _customSearchParams = {};
  _propertyNameMapping: PropertyMapping;                      // mapping of the user-friendly names to algolia search result properties
  _hits: any[] = [];                                          // search result hits from algolia
  _results: any;                                              // search results from algolia
  _searchFilters: GenericSearchFilter[];                      // filters for the search
  _searchResultTitleProperty: string;                         // Property to be used as the title of search results in Grid view
  _searchResultSubTitleProperty: string;                      // Property to be used as the sub-title of search results in Grid view
  _searchResultTooltipProperty: string;                       // Property to be used as the tooltip of search results in Grid view
  _searchResultsDistinct = false;                             // whether the search results be distinct or not (algolia config)
  _searchResultsPerPage = 30;                                 // how many search results to show per page
  _searchResultsCurrentPage = 0;                              // zero-based paging. current page selected from results
  _showTitlePropertyName = false;                             // toggle to show/hide search result title property name
  _showSubTitlePropertyName = false;                          // toggle to show/hide search result sub-title property name
  _showContentPropertyName = true;                            // toggle to show/hide search result content property name
  _clickableSearchResults = false;                            // toggle to make search results clickable or not
  _clickableSearchResultRouteProperty: string;                // search result property whose value to be used as dynamic route property
  _clickableSearchResultRoutePrefix: string;                  // prefix to be used for the dynamic route property (see example usage)
  _clickableSearchResultRouteComponent: Component;            // dynamic component to load on search result click
  _additionalInstanceData: any;                               // any additional instance data to be passed to the dynamic component
  _searchResultPaginationPadding = 2;                         // number of pages to show for pagination (instant-search config)
  _showSearchResultPaginationShowFirstButton: number;         // toggle for showing pagination first button (instant-search config)
  _showSearchResultPaginationShowLastButton: number;          // toggle for showing pagination last button (instant-search config)
  _showExactTermSearchToggle: boolean;                        // show toggle to turn on/off exact search
  _parentDialogRef: any;                                      // if the search is opened in a dialog, this is the reference to it
  _clickableSearchResultCallbackFunction: Function;           // callback function to be called when the search result is clicked
  _hideViewToggle: boolean;                                   // toggle to show/hide GRID/TILE view button
  _currentView: GenericSearchView;                            // current view selected - GRID/TILE
  _forceView: GenericSearchView;                              // force view selected, overriding user preference
  _customPlaceholderText = "Search";                          // custom input placeholder text
  _additionalActionTemplateData: any;                         // Template data for actions on search results
  _searchCardMaxColumns: number;                              // maximum columns to show in the GRID view
  _pinToLaunchpadFunction: Function;                          // Function to be called when pin to launchpad action is clicked
  _customSearchConfig: any;
  _customSearchQuery$: Observable<string>;
  GenericSearchView = GenericSearchView;                      // Views for generic search
  GenericSearchViewKeys: string[];                            // String representation of Generic Search views
  private gridApi: GridApi;                                   // the api of the ag-grid
  private gridColumnApi: ColumnApi;                           // stores the ag-grids column api
  innerWidth = 0;                                             // innerWidth of the Generic Search Component itself
  selectedFilter: GenericSearchFilter;                        // filter that's currently being updated
  selectedFilterIndex: number;                                // index of the current filter being updated
  _defaultColumnDef: ColDef;                                  // the default column definitions
  _rowHeight: number;                                         // the height of individual rows in Ag-Grid view
  _columnDefs: (ColDef | ColGroupDef)[];                      // stores definitions for each column
  _frameworkComponents;                                       // currently only stores tooltip component
  filterModel = {};                                           // filter object from ag-grid
  _agGridContext: any;                                        // Context of ag-grid for TILE view

  @ViewChild('genericSearch') elementRef: ElementRef;
  instantSearchRef: any;
  @ViewChild('instantSearchRef') set instantSearchRefContent(content: ElementRef) {
    if (content) { // initially setter gets called with undefined
      this.instantSearchRef = content;
      this.algoliaSearchRef.emit(this.instantSearchRef);
    }
  }
  @ViewChild('agGrid') agGrid: any;
  @ContentChild('cardTemplate') cardTemplate?: TemplateRef<any>;

  componentSubs: Subscription = new Subscription();
  searchTokenSub: Subscription;
  chatMinimized: boolean;                                     // is chat minimized or not
  tokenExpiry: number;
  loadingToken = true;
  showSearchComponent = true;
  exactSearchQuery = false;                                   // ngModel for triggering exact search queries on the index
  searchQuery = '';

  @Input() actionTemplate: TemplateRef<any>;
  @Input()
  set additionalActionTemplateData(additionalActionTemplateData: any) {
    this._additionalActionTemplateData = additionalActionTemplateData;
  }

  @Input()
  set rowHeight(rowHeight: number) {
    this._rowHeight = rowHeight;
  }
  @Input()
  set defaultColumnDef(defaultColumnDef: ColDef) {
    this._defaultColumnDef = defaultColumnDef;
  }
  @Input()
  set columnDefs(columnDefs: (ColDef | ColGroupDef)[]) {
    this._columnDefs = columnDefs;
  }

  @Input()
  set searchTitle(searchTitle: string) {
    this._searchTitle = searchTitle;
  }
  @Input()
  set searchSubTitle(searchSubTitle: string) {
    this._searchSubTitle = searchSubTitle;
  }
  @Input()
  set showSearchTitle(showSearchTitle: boolean) {
    this._showSearchTitle = showSearchTitle;
  }
  @Input()
  set showSearchSubTitle(showSearchSubTitle: boolean) {
    this._showSearchSubTitle = showSearchSubTitle;
  }
  @Input()
  set searchConfig(searchConfig: InstantSearchConfig) {
    this._searchConfig = searchConfig;
  }
  @Input()
  set indexName(indexName: string) {
    this._indexName = indexName;
    this._searchConfig = { indexName, searchClient: null };
  }
  @Input()
  set tokenType(type: AlgoliaTokenType) {
    this._tokenType = type;
  }
  @Input()
  set searchResultTitleProperty(searchResultTitleProperty: string) {
    this._searchResultTitleProperty = searchResultTitleProperty;
  }
  @Input()
  set searchResultSubTitleProperty(searchResultSubTitleProperty: string) {
    this._searchResultSubTitleProperty = searchResultSubTitleProperty;
  }
  @Input()
  set showTitlePropertyName(showTitlePropertyName: boolean) {
    this._showTitlePropertyName = showTitlePropertyName ? true : false;
  }
  @Input()
  set showSubTitlePropertyName(showSubTitlePropertyName: boolean) {
    this._showSubTitlePropertyName = showSubTitlePropertyName ? true : false;
  }
  @Input()
  set showContentPropertyName(showContentPropertyName: boolean) {
    this._showContentPropertyName = showContentPropertyName ? true : false;
  }
  @Input()
  set propertyNameMapping(propertyNameMapping: PropertyMapping) {
    this._propertyNameMapping = propertyNameMapping;
  }
  @Input()
  set searchFilters(searchFilters: GenericSearchFilter[]) {
    this._searchFilters = searchFilters;
  }
  @Input()
  set clickableSearchResults(clickableSearchResults: boolean) {
    this._clickableSearchResults = clickableSearchResults;
  }
  @Input()
  set clickableSearchResultRouteProperty(clickableSearchResultRouteProperty: string) {
    this._clickableSearchResultRouteProperty = clickableSearchResultRouteProperty;
  }
  @Input()
  set clickableSearchResultRoutePrefix(clickableSearchResultRoutePrefix: string) {
    this._clickableSearchResultRoutePrefix = clickableSearchResultRoutePrefix;
  }
  @Input()
  set searchResultPaginationPadding(searchResultPaginationPadding: number) {
    this._searchResultPaginationPadding = searchResultPaginationPadding;
  }
  @Input()
  set showSearchResultPaginationShowFirstButton(showSearchResultPaginationShowFirstButton: number) {
    this._showSearchResultPaginationShowFirstButton = showSearchResultPaginationShowFirstButton;
  }
  @Input()
  set showSearchResultPaginationShowLastButton(showSearchResultPaginationShowLastButton: number) {
    this._showSearchResultPaginationShowLastButton = showSearchResultPaginationShowLastButton;
  }
  @Input()
  set showExactTermSearchToggle(toggle: boolean) {
    this._showExactTermSearchToggle = toggle;
  }
  @Input()
  set clickableSearchResultRouteComponent(clickableSearchResultRouteComponent: Component) {
    this._clickableSearchResultRouteComponent = clickableSearchResultRouteComponent;
  }
  @Input()
  set parentDialogRef(parentDialogRef: any) {
    this._parentDialogRef = parentDialogRef;
  }
  @Input()
  set additionalInstanceData(additionalInstanceData: any) {
    this._additionalInstanceData = additionalInstanceData;
  }
  @Input()
  set clickableSearchResultCallbackFunction(clickableSearchResultCallbackFunction: any) {
    this._clickableSearchResultCallbackFunction = clickableSearchResultCallbackFunction;
  }
  @Input()
  set agGridContext(agGridContext: any) {
    this._agGridContext = agGridContext;
  }
  @Input()
  set hideViewToggle(hideViewToggle: boolean) {
    this._hideViewToggle = hideViewToggle;
  }
  @Input()
  set forceView(view: 'list'|'tiles') {
    if (view === 'list') {
      this._forceView = GenericSearchView.Grid;
    } else if (view === 'tiles') {
      this._forceView = GenericSearchView.Tiles;
    }
  }
  @Input()
  set customPlaceholderText(text: string) {
    this._customPlaceholderText = text;
  }
  @Input()
  set searchResultTooltipProperty(searchResultTooltipProperty: string) {
    this._searchResultTooltipProperty = searchResultTooltipProperty;
  }
  @Input()
  set searchResultsDistinct(value: boolean) {
    this._searchResultsDistinct = value;
  }
  @Input()
  set cardColumns(value: string) {
    if (value) {
      this._searchCardMaxColumns = Number(value);
    }
  }
  @Input()
  set pinToLaunchpadFunction(pinToLaunchpadFunction: Function) {
    this._pinToLaunchpadFunction = pinToLaunchpadFunction;
  }
  @Input()
  set customSearchParams(params: any) {
    // https://www.algolia.com/doc/api-reference/search-api-parameters/
    this._customSearchParams = params;
    this._searchParams = {...this._searchParams, ...this._customSearchParams};
  }
  @Input()
  set customSearchQueryObservable(query$: Observable<string>) {
    this._customSearchQuery$ = query$;
  }
  @Output() algoliaSearchRef: EventEmitter<any> = new EventEmitter<any>();
  @Output() agGridRef: EventEmitter<any> = new EventEmitter<any>();
  @Output() filterModelEvent: EventEmitter<any> = new EventEmitter<any>();
  @Output() searchResultsChange: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private searchValueModifier: SearchValueModifierService,
    public instantSearch: InstantSearchService,
    private chatService: ChatService,
    private multiViewService: MultiViewService,
    private userService: UserService,
    private searchAuthService: SearchAuthService
  ) { }

  async ngOnInit() {
    // check for custom search query observable
    if (this._customSearchQuery$) {
      this.componentSubs.add(
        this._customSearchQuery$.subscribe(query => this.doSearch(query))
      );
    }

    this.createSearchConfig();

    this.componentSubs.add(
      this.instantSearch.getConfigByIndex(this._indexName).subscribe((params) => {
        // get index search params and any custom params being passed as input and combine them all into a single params object.
        this._searchParams = {...this._searchParams, ...this._customSearchParams, ...params};
      })
    );

    // re-size ag-grid width when chat is minimized or maximized
    this.componentSubs.add(this.chatService.isChatMinimized$.pipe(debounceTime(300)).subscribe(() => {
      this.gridApi?.sizeColumnsToFit();
    }));

    this.GenericSearchViewKeys = Object.keys(GenericSearchView).filter(key => !isNaN(Number(GenericSearchView[key])));

    if (this._forceView) {
      this._currentView = this._forceView;
    } else {
      // get the user preferences for the view type selected
      const userPreferences = await this.userService.getUserPreferences();
      if (userPreferences) {
        this._currentView = userPreferences.genericSearchView;
      }
      if (!this._currentView) {
        this._currentView = GenericSearchView.Tiles;
      }
    }

    this._frameworkComponents = {
      CustomTooltipComponent: CustomTooltipComponent,
      actionTemplateRenderer: ActionTemplateRendererComponent
    };

    this.componentSubs.add(
      this.chatService.isChatMinimized$.subscribe(isChatMinimized => {
        this.chatMinimized = isChatMinimized;
        if (this.gridApi && isChatMinimized && this._currentView === GenericSearchView.Grid) {
          setTimeout(() => this.gridApi.sizeColumnsToFit(), 500);
        } else if (this.gridApi && !isChatMinimized && this._currentView === GenericSearchView.Grid) {
          // const allColumnIds = [];

          // this.gridColumnApi.getAllColumns().forEach(function (column) {
          //   allColumnIds.push(column.getColId());
          // });

          // setTimeout(() => this.gridColumnApi.autoSizeColumns(allColumnIds), 500);
          // setTimeout(() => this.gridApi.sizeColumnsToFit(), 500);
        }
      })
    );
  }

  ngAfterViewInit() {
    this.algoliaSearchRef.emit(this.instantSearchRef);
    this.agGridRef.emit(this.agGrid);
    document.addEventListener('click', this.closeFilterDialog);
  }

  doSearch(query?: string): void {
    if (query) {
      if (this.exactSearchQuery && query[0] === '"' && query[query.length - 1] === '"') {
        // Strip quotes from query beginning and end, then bind it to searchQuery
        this.searchQuery = query.substring(1, query.length - 1);
      } else {
        this.searchQuery = query;
      }
    }
    this._searchParams = {
      ...this._searchParams,
      ...this._customSearchParams,
      hitsPerPage: this._searchResultsPerPage,
      page: this._searchResultsCurrentPage,
      query: query
    };
  }

  createSearchConfig() {
    this.loadingToken = true;

    // Set up search token sub
    if (this.searchTokenSub) {
      this.searchTokenSub.unsubscribe();
    }

    this.searchTokenSub = this.searchAuthService.getSearchToken(this._tokenType).subscribe((tokenData) => {
      this.tokenExpiry = tokenData.validUntil;
      this._searchConfig = {
        indexName: this._indexName,
        searchClient: algoliasearch(environment.algolia.appId, tokenData.securedAPIKey) as any
      };

      this.loadingToken = false;
      this.algoliaSearchRef.emit(this.instantSearchRef);
    });
  }

  /**
   * this closes the filter dialog box when user clicks outside the filters class
   *
   * @event event Mouse click event
   */
  closeFilterDialog = (event: MouseEvent) => {
    if (!document.getElementsByClassName('generic-search-filter-container')[0]?.contains(<Node>event.target)
      && !document.getElementsByClassName('filters')[0]?.contains(<Node>event.target)
      && !document.getElementsByClassName('cdk-overlay-connected-position-bounding-box')[0]?.contains(<Node>event.target)) {
      this.selectedFilter = null;
      this.selectedFilterIndex = null;
    }
  }

  ngOnDestroy() {
    this.componentSubs.unsubscribe();
    document.removeEventListener('click', this.closeFilterDialog);
    if (this.searchTokenSub) {
      this.searchTokenSub.unsubscribe();
    }
  }

  /**
   * This function gets the properties for the content of search results without the title and subtitle properties
   */
  getProperties(data: any): string[] {
    const returnedData = {};

    this._propertyNameMapping.properties.forEach(property => {
      if (property.optional ? data[property.propertyName + '-modified'] ? true : data[property.propertyName] : true) {
        returnedData[property.propertyName] = data[property.propertyName];
      }
    });

    delete returnedData[this._searchResultTitleProperty];
    delete returnedData[this._searchResultSubTitleProperty];

    return Object.keys(returnedData);
  }

  /**
   * This function gets the user friendly name of the property
   *
   * @param property actual property on the search result object
   */
  getPropertyUserFriendlyName(property: string): string {
    const currentProperty = this._propertyNameMapping.properties.find(mappedProperty => {
      if (mappedProperty.propertyName === property) {
        return true;
      }
      return false;
    });

    return currentProperty && currentProperty.propertyUserFriendlyName ? currentProperty.propertyUserFriendlyName : '';
  }

  /**
   * This function fires when algolia search is changed with new query
   *
   * @param { results, state } Response Response from algolia
   */
  onSearchChange({ results, state }: { results: any, state: any }) {
    if (results) {
      this.searchResultsChange.emit(results);
      this._results = results;
    }
    if (results && results.hits) {
      this._hits = results.hits;

      this._hits.forEach(hit => {
        this._propertyNameMapping.properties.forEach(property => {
        if (property.valueModifiers !== undefined && hit[property.propertyName] !== undefined && property.valueModifiers.length > 0) {
          let modifierIndex = 0;
          for (const modifier of property.valueModifiers) {
            if (modifierIndex === 0) {
              hit[property.propertyName + '-modified'] = this.searchValueModifier.modifyValues(modifier, hit[property.propertyName], hit);
            } else {
              hit[property.propertyName + '-modified']
                = this.searchValueModifier.modifyValues(modifier, hit[property.propertyName + '-modified'], hit);
            }
            modifierIndex++;
          }
        }
        });
      });

      const filters = this._searchFilters ? this._searchFilters.filter(searchFilter => searchFilter.defaultValue) : [];
      for (const filter of filters) {
        this.filterSearch(filter);
      }
    }
  }


  @HostListener('window:resize', ['$event'])
  onResize(event: any): void {
    // on resize, update the ag-grid width
    this.gridApi?.sizeColumnsToFit();
  }

  /**
   * This function opens the selected filter view
   *
   * @param selectedFilter Currently selected filter
   * @param selectedFilterIndex Index of the selected filter
   */
  openFilterDialog(selectedFilter: GenericSearchFilter, selectedFilterIndex: number): void {
    if (this.selectedFilter
      && this.selectedFilter.filterName === selectedFilter.filterName
      && this.selectedFilterIndex === selectedFilterIndex) {
      this.selectedFilter = null;
      this.selectedFilterIndex = null;
    } else {
      this.selectedFilter = selectedFilter;
      this.selectedFilterIndex = selectedFilterIndex;
    }
  }

  /**
   * This function updates the search results with new filter values
   *
   * @param filterValue Value of the filter as selected by the user
   * @param attribute property being filtered
   * @param filterType Type of filter
   * @param isNegated Whether this filter value is Negation of truth or not - e.g. NOT TRUE/NOT FALSE etc.
   * @param forceRefresh Whether to forcefully refresh the search state or not
   */
  refineState(filterValue: any, attribute: string, filterType: GenericSearchFilterType, isNegated?: boolean, forceRefresh?: boolean): void {
    const index = this._searchConfig.indexName;
    let filterString: any;
    if (filterType === 'date-range') {
      filterString = this.instantSearch.createRangeString(filterValue);
    } else if (filterType === 'select' && Array.isArray(filterValue)) {
      filterString = filterValue;
    }

    if ((filterString !== null && typeof filterString !== 'undefined') || forceRefresh) {
      // if ((Array.isArray(filterString) && filterString.length > 0 && (filterString[0] === null || typeof filterString === 'undefined'))
      //   && !forceRefresh) {
      if ((Array.isArray(filterString) && filterString.length > 0 && (typeof filterString === 'undefined'))
        && !forceRefresh) {
        return;
      }
      this.instantSearch.updateSearchConfig(index, {
        filters: {
          attribute: attribute,
          value: filterString,
          isNegated
        },
        distinct: this._searchResultsDistinct,
        hitsPerPage: this._searchResultsPerPage,
        page: this._searchResultsCurrentPage
      }, 'OR');
    }
  }

  /**
   * Adjusts the timestamps if both numbers are the same so we can search the entire day
   *
   * @param range Array with 2 numbers representing start and end date
   */
  adjustIfSameDay(range: number[]): number[] {
    // set the second range value to the end of the day
    if (range[1]) {
      range[1] = range[1] + MS_IN_DAY;
    }
    return range;
  }

  /**
   * This function filters the search based on the filter values
   *
   * @param data filter being updated
   */
  filterSearch(data: GenericSearchFilter): void {
    if (!data) {
      return;
    }

    if (data.type === 'date-range') {
      const startDate = data.defaultValue[0];
      const endDate = data.defaultValue[1];

      let range = [startDate, endDate];
      if (typeof startDate === 'string') {
        range[0] = new Date(startDate).getTime();
      }
      if (typeof endDate === 'string') {
        range[1] = new Date(endDate).getTime();
      }

      range = this.adjustIfSameDay(range);
      let forceRefresh = false;
      if (!range[0] && !range[1]) {
        forceRefresh = true;
      }
      this.refineState(range, data.attribute, data.type, null, forceRefresh);
    } else if (data.type === 'select') {
      const defaultValue = data.defaultValue;
      this.refineState(defaultValue, data.attribute, data.type, data.negatedDefaultValue);
    }
  }

  /**
   * This function is called when the search result is clicked
   *
   * @param item clicked search result item
   */
  openSearchResultItem(item: any): void {
    if (!this._clickableSearchResults) {
      return;
    }
    // if this item was clicked when the search is in a dialog then close the dialog
    if (this._parentDialogRef && this._parentDialogRef.close) {
      this._parentDialogRef.close({ data: item });
    } else {
      // else if a callback function is provided for the event of clicking on the item then call that function
      if (this._clickableSearchResultCallbackFunction) {
        this._clickableSearchResultCallbackFunction(item);
      } else {
        // otherwise open a new tab with provided additionalInstanceData
        const additionalInstanceData = {};
        if (this._additionalInstanceData) {
          this._additionalInstanceData.forEach(data => {
            additionalInstanceData[data.property] = data.value;
            if (data.value === this._clickableSearchResultRouteProperty) {
              additionalInstanceData[data.property] = item[this._clickableSearchResultRouteProperty];
            }
          });
        }
        // open new tab here for multi-view
        this.multiViewService.setCreateNewTab({
          component: this._clickableSearchResultRouteComponent,
          tabName: 'Loading...',
          additionalInstanceData: additionalInstanceData
        });
      }
    }
  }

  /**
   * This function runs when a value is selected from drop-down within the search result (e.g. version selection for interactions)
   *
   * @param event Event of selecting value in drop-down
   * @param hit Search result object being updated
   * @param property property being updated
   */
  onSelectionChange(event: any, hit: any, property: string): void {
    hit[this._propertyNameMapping.properties.find(prop => prop.propertyName === property).defaultValueProperty] = event.value;
  }

  /**
   * This function toggles the search view - GRID/TILE
   *
   * @param view Selected search view
   */
  changeView(view: GenericSearchView): void {
    this._currentView = view;
    if (view === this.GenericSearchView.Grid) {
      setTimeout(() => this.gridApi?.sizeColumnsToFit(), 300);
    }
  }

  /**
   * Called when the grid is ready. Does not load data if the user does not have search access
   *
   * @param params this is an object that contains the Api, the columnApi and the grids type
   */
  onGridReady(params: any): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.setAutoHeight();
    this.onColumnResized();
    this.gridApi.applyTransaction(<any>this._hits);

    this.agGridRef.emit(this.agGrid);
  }

  /**
   * Sets the gridApis DOM layout to automatically detect the height based on the number of rows
   */
  setAutoHeight() {
    this.gridApi.setDomLayout('autoHeight');
  }

  /**
   * This function gets called when ag-grid column is resized
   */
  onColumnResized() {
    this.gridApi.resetRowHeights();
  }

  /**
   * This is called when the first data in the grid is rendered
   *
   * @param params this is an object that contains the Api, the columnApi and the grids type
   */
  async onFirstDataRendered(params): Promise<void> {
    if (this.chatMinimized) {
      params.api.sizeColumnsToFit();
    }
    params.api.resetRowHeights();
  }

  /**
   * This functions gets called when ag-grid filter is changed
   *
   * @param event Filter change event
   */
  onFilterChange(event: any): void {
    this.filterModel = (<GridApi>event.api).getFilterModel();
    this.filterModelEvent.emit(this.filterModel);
    // get filter model from ag-grid for current filter
    const filterModel = (<GridApi>event.api).getFilterModel();
    // for every property bring filtered
    for (const key in filterModel) {
      // if operator property exists then filter based on multiple conditions
      if (filterModel[key].operator) {
        this.searchForMultipleConditions(key, filterModel);
      } else {
        this.searchForSingleCondition(key, filterModel);
      }
    }
  }

  /**
   * This function searches algolia for multiple filter conditions
   *
   * @param key property being filtered
   * @param filterModel condition object from ag-grid
   */
  searchForMultipleConditions(key: string, filterModel: any): void {
    const condition1 = filterModel[key].condition1;
    const condition2 = filterModel[key].condition2;
    const operator = filterModel[key].operator;
    let filter = '';

    switch (condition1.filterType) {
      case 'date':
        filter += this.getDateFilterString(condition1.type, key, condition1) + ` ${operator} `;
        break;
      case 'text':
        filter += this.getTextFilterString(condition1.type, key, condition1) + ` ${operator} `;
        break;
      default: break;
    }
    switch (condition2.filterType) {
      case 'date':
        filter += this.getDateFilterString(condition2.type, key, condition2);
        break;
      case 'text':
        filter += this.getTextFilterString(condition2.type, key, condition2);
        break;
      default: break;
    }
    // search algolia with combined filter string
    this.filteredSearch(filter);
  }

  /**
   * This function searches algolia for single filter condition
   *
   * @param key property being filtered
   * @param filterModel condition object from ag-grid
   */
  searchForSingleCondition(key: string, filterModel: any): void {
    switch (filterModel[key].filterType) {
      case 'date':
        switch (filterModel[key].type) {
          case 'equals':
            this.filteredSearch(`${key}\:${moment(filterModel[key].dateFrom).toDate().getTime()} `
              + `TO ${moment(filterModel[key].dateFrom).toDate().getTime() + 86400000}`);
            break;
          case 'notEqual':
            this.filteredSearch(`NOT ${key}\:${moment(filterModel[key].dateFrom).toDate().getTime()} `
              + `TO ${moment(filterModel[key].dateFrom).toDate().getTime() + 86400000}`);
            break;
          default: break;
        }
        break;
      case 'text':
        switch (filterModel[key].type) {
          case 'equals':
            this.filteredSearch(`${key}\:'${filterModel[key].filter}'`);
            break;
          case 'notEqual':
            this.filteredSearch(`NOT ${key}\:'${filterModel[key].filter}'`);
            break;
          case 'contains':
            this.querySearch(`${filterModel[key].filter}`);
            break;
          default: break;
        }
        break;
      default: break;
    }
  }

    /**
   * This function searches algolia with filters passed
   *
   * @param filter Filter for algolia search data
   */
  filteredSearch = (filter: string): void => {
    this._searchConfig.searchClient.search(
      [(<SearchRequest>{
        indexName: this._searchConfig.indexName,
        params: {
          filters: (this._searchConfig.searchParameters ? this._searchConfig.searchParameters.filters : '')
          // filters: ('')
            + ' AND ' + filter
        }
      })]
    ).then(data => {
      this.gridApi.applyTransaction(<any>data.results[0].hits);
    });
  }

  /**
   * This function searches algolia with query passed
   *
   * @param filter Query for algolia search data
   */
  querySearch(query: string): void {
    this._searchConfig.searchClient.search(
      [({
        indexName: this._searchConfig.indexName,
        params: {
          query: query
        }
      })]
    ).then(data => {
      this.gridApi.applyTransaction(<any>data.results[0].hits);
    });
  }

  /**
   * This function returns the filter string for algolia for text element from ag-grid filter data
   *
   * @param condition Condition type
   * @param key property being filtered
   * @param filterModel condition object from ag-grid
   */
  getTextFilterString(condition: string, key: string, filterModel: any): string {
    switch (condition) {
      case 'equals':
        return `${key}\:'${filterModel.filter}'`;
      case 'notEqual':
        return `NOT ${key}\:'${filterModel.filter}'`;
      case 'contains':
        break;
      case 'notContains':
        break;
      case 'startsWith':
        break;
      case 'endsWith':
        break;
      default: return '';
    }
  }

  /**
   * This function returns the filter string for algolia for date element from ag-grid filter data
   *
   * @param condition Condition type
   * @param key property being filtered
   * @param filterModel condition object from ag-grid
   */
  getDateFilterString(condition: string, key: string, filterModel: any): string {
    switch (condition) {
      case 'equals':
        return `${key}\:${moment(filterModel.dateFrom).toDate().getTime()}`;
      case 'notEqual':
        return `NOT ${key}\:${moment(filterModel.dateFrom).toDate().getTime()}`;
      case 'greaterThan':
        return '';
      case 'lessThan':
        break;
      case 'inRange':
        break;
      default: return '';
    }
  }

  /**
   * This function is invoked when a row is clicked in ag-grid TILE view
   */
  onRowClicked(event: any): void {
    if (this._clickableSearchResults) {
      if (this._clickableSearchResultCallbackFunction) {
        this._clickableSearchResultCallbackFunction(event.data);
      } else if (this._clickableSearchResultRouteProperty) {
        this.openSearchResultItem(event.data);
      }
    }
  }

  /**
   * Triggered by checking the exact search checkbox on the template
   * @returns void
   */
  toggleExactSearch(): void {
    const exactSearchParams = {advancedSyntax: true, advancedSyntaxFeatures: ['exactPhrase'], typoTolerance: false};
    const newSearchParams = {...this._searchParams, ...this._customSearchParams, ...exactSearchParams};
    newSearchParams['query'] = `"${this.searchQuery}"`;
    if (this.exactSearchQuery) {
      // add params to our search params to enable exact searching
      this._searchParams = newSearchParams;
      return;
    }
    // remove params that enable exact searching
    // recreate search params
    const keysToBeRemoved = Object.keys(exactSearchParams);
    // restructure the searchParams without the advanced search parameters (since we don't know what's in the searchParams)
    const cleanedSearchParams = Object.keys(this._searchParams).reduce(
      (acc, cur) => {
        if (!keysToBeRemoved.includes(cur)) {
          acc[cur] = this._searchParams[cur];
        }
        return acc;
      }, {}
    );
    cleanedSearchParams['query'] = this.searchQuery;
    this._searchParams = cleanedSearchParams;
  }

  searchInputChanged(query: string): void {
    // update query to be exact by applying quotes around search term in search filter
    if (this.exactSearchQuery) {
      query = `"${query}"`;
    }
    this.doSearch(query);
  }

  paginatorPageChange(event: any): void {
    // page size change
    if (event.pageSize && event.pageSize !== this._searchResultsPerPage) {
      this._searchResultsPerPage = event.pageSize;
      // reset current page to 0 to show results
      this._searchResultsCurrentPage = 0;
      this.doSearch();
      return;
    }
    // page change
    if (event.previousPageIndex !== event.pageIndex) {
      this._searchResultsCurrentPage = event.pageIndex;
      this.doSearch();
    }
  }
}
