import { Component, OnInit, Inject, TemplateRef } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { LaunchPadMenuItem, LaunchPadMenuItemGroup } from '@eva-model/menu/menu';
import { LaunchPadService } from '@eva-services/nav/launch-pad.service';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-launch-pad-edit',
  templateUrl: './launch-pad-edit.component.html',
  styleUrls: ['./launch-pad-edit.component.scss']
})
export class LaunchPadEditComponent implements OnInit {

  menu$: Observable<LaunchPadMenuItemGroup[]>;
  userItemsGroupId = 'ji864Yx1Sfk67MMn0G8I';

  constructor(private launchPadService: LaunchPadService,
              public dialog: MatDialog,
              private snackBar: MatSnackBar) { }

  ngOnInit(): void {
    this.menu$ = this.launchPadService.menu$;
  }

  reorderMenuItemsByNamePrompt(confirmDialog: TemplateRef<any>) {
    const reorderConfirmDialog = this.dialog.open(confirmDialog);
    reorderConfirmDialog.afterClosed().pipe(take(1)).subscribe((reorderConfirmation) => {
      if (!!reorderConfirmation) {
        this.reorderMenuItemsByName();
      }
    })
  }

  // Orders menu items by name ascending
  reorderMenuItemsByName() {
    this.menu$.pipe(take(1), map((groups) => {
      const userItemsGroups = groups.filter(group => group.id === this.userItemsGroupId);
      return userItemsGroups[0];
    })).subscribe((group) => {
      const reordered = group.items.sort(((a, b) => (a.title > b.title) ? 1 : -1))
      // Loop through each one to save
      reordered.forEach((item, index) => {
        this.launchPadService.reorderGroupItem(item.id, item.groupId, index)
      })
      this.snackBar.open(`Sorted Your Launchpad items!`, '', { duration: 3000 });
    })
  }

  dropMenuItem(event: CdkDragDrop<string[]>, list: any[], groupId: string) {
    // console.log(event);

    // return if the position hasn't changed
    if (event.previousIndex === event.currentIndex) {
      return;
    }

    // moveItemInArray(list, event.previousIndex, event.currentIndex);

    // if (groupId === 'pinned') {
    //   this.launchPadService.reorderPinnedItem(event.item.data.id, event.currentIndex);
    //   return;
    // }

    this.launchPadService.reorderGroupItem(event.item.data.id, groupId, event.currentIndex);
  }

  pinMenuItem(id: string) {
    this.launchPadService.pinItem(id);
  }

  unpinMenuItem(id: string) {
    this.launchPadService.unpinItem(id);
  }

  removeUserItem(item: LaunchPadMenuItem, index: number) {
    this.launchPadService.removeCustomItem(item, index);
  }

}
