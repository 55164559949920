import { Injectable } from '@angular/core';
import { FirestoreService } from '@eva-services/firestore/firestore.service';
import { map, take } from 'rxjs/operators';
import { ReleaseNotes } from '@eva-model/releaseNotes';
import { Observable } from 'rxjs';
import { AngularFirePerformance, trace } from '@angular/fire/compat/performance';
import { HtmlSanitization } from '@eva-model/htmlSanitization';

@Injectable({
  providedIn: 'root'
})
export class ReleaseNotesService {

  _releaseNotesPath = 'About/ReleaseNotes';

  constructor(
    private firestoreService: FirestoreService,
    private perf: AngularFirePerformance
  ) { }

  /**
   * This gets the release notes and returns the document.
   */
  getReleaseNotes(): Observable<ReleaseNotes> {
    return this.firestoreService.doc$<ReleaseNotes>(this._releaseNotesPath).pipe(
      trace('release-notes-getReleaseNotes'),
      map((res) => {
        const HtmlSanitizer = new HtmlSanitization();
        for (let i = 0; i < res.releaseNotes.length; i++) {
          res.releaseNotes[i].description = HtmlSanitizer.clean(res.releaseNotes[i].description);
        }
        return res;
      })
    );
  }

  /**
   * This stores the release notes into a new object.
   * @param releaseNotes
   */
  storeReleaseNotes(releaseNotes: ReleaseNotes) {
    return this.firestoreService.set(this._releaseNotesPath, releaseNotes);
    // console.log(releaseNotes);
    // return Promise.resolve({});
  }
}
