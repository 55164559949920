<mat-progress-bar *ngIf="loadingDifferentVersion" mode="indeterminate"></mat-progress-bar>

<!-- Loading Knowledge Doc -->
<ng-container *ngIf="loadingDoc">
  <app-spinner name="getting-knowledge-update" show="true">
    <h4>Fetching Knowledge...</h4>
  </app-spinner>
</ng-container>

<div fxLayout="row" class="knowledge-document-editor" fxFill>
  <div fxFlex="22" *ngIf="(showFeedbackPanel$ | async) as showFeedbackPane">
    <div *ngIf="!loadingDoc">
      <app-knowledge-feedback [docId]="knowledgeDoc.id" [groupKey]="knowledgeDoc.groupPublicKey" [version]="knowledgeDoc.versionNumber"></app-knowledge-feedback>
    </div>
  </div>
  <div [fxFlex]="(showFeedbackPanel$ | async) ? '56' : '78'">
    <!--- Editor -->
    <div [froalaEditor]="froalaOptions" [(froalaModel)]="froalaModel" (froalaInit)="initialize($event)" (froalaModelChange)="froalaChange($event)" #editor></div>
  </div>
  <div fxFlex="22" class="document-form-panel">
    <!--- Doc Meta Stuff -->
    <div appStickyElement *ngIf="!loadingDoc">
      <!-- If the view has a version number in URL, we show a message stating it may not be the latest to prevent stale data
      being present as new -->
      <mat-card *ngIf="showOutOfDateWarning" class="warning-old-version">
        <div fxLayout="row" fxLayoutAlign="space-between center">
          <div>
            You are currently viewing an old version of this document
          </div>
          <div>
            <button mat-button color="primary" (click)="getLatestDocVersion()">View Latest</button>
          </div>
        </div>
      </mat-card>

      <form [formGroup]="docForm">

        <div class="document-save-controls">

          <div matTooltip="You are not a member of the group that owns this document" [matTooltipDisabled]="isUserMemberOfGroup === true" matTooltipPosition="left">
            <button mat-flat-button color="primary" class="button-save-document" (click)="saveDocument(savingDialog)" [disabled]="isSaving || !isUserMemberOfGroup || isDeleted">
              <span *ngIf="shouldSaveAsDraft">Save as Draft</span>
              <span *ngIf="!shouldSaveAsDraft">Publish</span>
              <mat-spinner *ngIf="isSaving" diameter="20" mode="indeterminate"></mat-spinner>
            </button>
          </div>

          <div class="draft-and-unsaved">
            <div>
              <mat-checkbox formControlName="draft">Draft</mat-checkbox>
            </div>
            <div>
              <p class="unsaved-message">
                <span *ngIf="(unsavedChanges$ | async); else noUnsaved">Unsaved Changes</span>
                <ng-template #noUnsaved>&nbsp;</ng-template>
              </p>
            </div>
          </div>
          
        </div>

        <!-- Global Find and Replace -->
        <ng-container *ngIf="knowledgeDoc && !knowledgeDoc.isNewDoc">
          <hr/>
          <div class="mat-list-base">
            <h3 class="mat-subheader">
              <span>Find and Replace</span>
              <span style="flex-grow: 1;"></span>
              <a [routerLink]="[]" (click)="toggleFindAndReplace()">
                <ng-container *ngIf="findAndReplaceVisible">Hide</ng-container>
                <ng-container *ngIf="!findAndReplaceVisible">Show</ng-container>
              </a>
            </h3>
            <ng-container *ngIf="findAndReplaceVisible">
              <div class="document-input-controls">
                <form [formGroup]="findAndReplaceForm" (keydown.enter)="$event.preventDefault()">
                  <mat-form-field>
                    <input matInput type="text" autocomplete="off" formControlName="find" placeholder="Find" (keyup.enter)="findAndReplaceDirection('next')">
                    <mat-hint *ngIf="findAndReplaceOccurences && findAndReplaceOccurences.length === 0">No matches found</mat-hint>
                    <mat-hint *ngIf="findAndReplaceOccurences && findAndReplaceOccurences.length > 0">{{ findAndReplaceIndex + 1 }} of {{ findAndReplaceOccurences.length }}</mat-hint>
                  </mat-form-field>
                  <mat-form-field>
                    <input matInput type="text" autocomplete="off" formControlName="replace" placeholder="Replace with">
                  </mat-form-field>
                  <div style="margin:1rem 0;"><mat-checkbox formControlName="matchCase">Match case</mat-checkbox></div>
                  <div style="display: grid; grid-template-columns: repeat(2, 1fr); column-gap: 10px; row-gap: 1em;">
                    <div><button mat-stroked-button color="primary" style="width:100%;" [disabled]="!findAndReplaceOccurences || findAndReplaceOccurences.length === 0" (click)="findAndReplaceAction(findAndReplaceOccurences[findAndReplaceIndex].elementId)">Replace</button></div>
                    <div><button mat-stroked-button color="primary" style="width:100%;" [disabled]="!findAndReplaceOccurences || findAndReplaceOccurences.length === 0" (click)="findAndReplaceAll()">Replace all</button></div>
                    <div><button mat-flat-button color="primary" style="width:100%;" (click)="findAndReplaceDirection('prev')">Previous</button></div>
                    <div><button mat-flat-button color="primary" style="width:100%;" (click)="findAndReplaceDirection('next')">Next</button></div>
                  </div>
                </form>
              </div>
            </ng-container>
          </div>
          <div class="mat-list-base" *ngIf="findAndReplaceVisible && findAndReplaceStatus && findAndReplaceStatus.upcoming">
            <div class="document-input-controls">
              <hr/>
            </div>
            <h3 class="mat-subheader">Next Document in Queue</h3>
            <div class="document-input-controls">
              <p style="margin-top:0;">{{ findAndReplaceStatus.upcoming.documentName }}</p>
              <button mat-stroked-button color="primary" (click)="openNextDocument(findAndReplaceUnsavedConfirm)">Go to Next Document</button>
            </div>
          </div>
        </ng-container>

        <hr/>

        <div class="document-input-controls">
          <mat-form-field class="example-full-width">
            <input matInput type="text" autocomplete="off" formControlName="name" placeholder="Document Name">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Group</mat-label>
            <mat-select formControlName="group">
              <mat-option [value]="group.groupPublicKey" *ngFor="let group of knowledgeGroups">
                {{ group.groupName }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="example-full-width">
            <mat-label>Revision Notes (Optional)</mat-label>
            <textarea formControlName="revisionNote" matInput rows="3" maxlength="2000"></textarea>
          </mat-form-field>
        </div>

        <mat-nav-list>
          <h3 mat-subheader>Document Options</h3>
          <mat-list-item *ngIf="versions.length > 0" (click)="showVersions()">
            <mat-icon matListIcon>update</mat-icon>
            <h4 mat-line><span *ngIf="versions.length > 0">{{versions.length}} </span>Revisions</h4>
          </mat-list-item>
          <mat-list-item *ngIf="versions.length > 0" (click)="showFeedback()">
            <mat-icon matListIcon>feedback</mat-icon>
            <h4 mat-line>Feedback</h4>
          </mat-list-item>
          <mat-list-item *ngIf="!knowledgeDoc.isNewDoc" (click)="deleteKnowledgeDocument()">
            <mat-icon matListIcon>{{ isDeleted ? 'undo' : 'archive' }}</mat-icon>
            <h4 mat-line>{{ isDeleted ? 'Restore Document' : 'Archive Document' }}</h4>
          </mat-list-item>
        </mat-nav-list>

      </form>
    </div>
  </div>
</div>

<ng-template #findAndReplaceUnsavedConfirm>
  <h1 mat-dialog-title>Unsaved Changes</h1>
  <div mat-dialog-content>The current document has unsaved changes. Please save the document and then continue.<br/><br/></div>
  <div mat-dialog-actions>
    <button mat-stroked-button mat-dialog-close>Cancel</button>
    <button mat-stroked-button color="warn" [mat-dialog-close]="true">Continue Anyways</button>
  </div>
</ng-template>

<ng-template #savingDialog>
  <mat-spinner diameter="20" style="float:left;margin-right:24px;"></mat-spinner>{{shouldSaveAsDraft ? 'Saving document as draft' : 'Publishing document for next training'}}...
</ng-template>
