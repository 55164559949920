import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GeneralDialogModel } from '@eva-model/generalDialogModel';
import { Observable, combineLatest, BehaviorSubject, Subject } from 'rxjs';
import { environment } from '@environments/environment';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ChildGroupInvitationService } from '@eva-services/childGroupInvitation/child-group-invitation.service';
import { LoggingService } from '@eva-core/logging.service';
import { FirestoreService } from '@eva-services/firestore/firestore.service';
import { LogService } from '@eva-core/log/log.service';
import { LogLevel, EntityType, Log } from '@eva-model/log';
import { EvaGlobalService } from '@eva-core/eva-global.service';
import { distinctUntilChanged, map, filter, debounceTime } from 'rxjs/operators';
import { Group } from '@eva-model/group';

@Component({
  selector: 'app-share-group-dialog',
  templateUrl: './share-group-dialog.component.html',
  styleUrls: ['./share-group-dialog.component.scss']
})
export class ShareGroupDialogComponent implements OnInit {

  private _group: any;
  private _queryLoading = new BehaviorSubject<boolean>(false);
  private _showGroupSearchResults = new BehaviorSubject<boolean>(false);
  private _existingInvitations: any[] = [];

  isSending = false;
  existingInvitationsLoaded = false;
  sharedGroupInfo: any[] = [];
  sharedGroupInfoLoaded = false;
  query = '';
  queryLoading: Observable<boolean> = this._queryLoading.asObservable();
  groupSearchResults: any[];
  groupSearchQuery = new Subject<string>();
  selectedGroups: any[] = [];
  showGroupSearchResults: Observable<boolean> = this._showGroupSearchResults.asObservable();

  createInvitationForm: UntypedFormGroup;

  @ViewChild('queryInput') private _queryInput: ElementRef;

  /**
   * Constructor
   *
   * @param dialogData - data for this dialog, including the group it is for
   * @param _dialogRef - Reference to the dialog for this component
   * @param _firestoreAtbDynamicService - firestore service for ATB Dynamic forms database
   * @param _firestoreService - firestore service for EVA database
   * @param _formBuilder - FormBuilder to help configure the send invitation form
   * @param _childGroupInvitationService - the service for handling child group invitations
   * @param _loggingService - service for logging messages to user
   * @param _logService - service for logging messages to firebase
   * @param _evaGlobalService - EVA's global service (used to get user's ID)
   */
  constructor(@Inject(MAT_DIALOG_DATA) public dialogData: GeneralDialogModel,
    private _dialogRef: MatDialogRef<ShareGroupDialogComponent>,
    private _firestoreService: FirestoreService,
    private _formBuilder: UntypedFormBuilder,
    private _childGroupInvitationService: ChildGroupInvitationService,
    private _loggingService: LoggingService,
    private _logService: LogService,
    private _evaGlobalService: EvaGlobalService) {

      this._group = dialogData.extra;

      this.createInvitationForm = this._formBuilder.group(
        {
          'invitationComment': ['']
        });
  }

  /**
   * Initializes this component
   */
  ngOnInit() {
    this.loadSharedGroupInfo();
    this.loadExistingInvitations();
    this.setGroupSearchQuery();
  }

  /**
   * Loads the groups already being shared by the current group
   * in order to display them to the user.
   */
  async loadSharedGroupInfo() {
    try {
      this.sharedGroupInfoLoaded = false;
      let childGroupInfoCount = 0;
      this.sharedGroupInfo = [];
      if (this._group.childGroupPublicKeys.length > 0) {
        const promiseArray: Promise<any>[] = [];
        this._group.childGroupPublicKeys.forEach(groupPublicKey => {
          promiseArray.push(this._firestoreService.col('GroupSigningKeys').doc(groupPublicKey).ref.get());
        });
        const promiseResults = await Promise.all(promiseArray);
        promiseResults.forEach(groupDoc => {
          if (groupDoc && groupDoc.exists) {
            const data = groupDoc.data();
            this.sharedGroupInfo.push({
              groupName: data.groupName,
              groupPublicKey: data.groupPublicKey,
              groupType: data.groupType,
              isRemoving: false
            });
            childGroupInfoCount++;
          }
        });
        if (childGroupInfoCount === this._group.childGroupPublicKeys.length) {
          this.sharedGroupInfoLoaded = true;
        }
      } else {
        this.sharedGroupInfoLoaded = true;
      }
    } catch (err) {
      await this.logToFirebase('Error retrieving child groups: ' + err);
      this.sharedGroupInfoLoaded = false;
    }
  }

  /**
   * Loads any existing invitations (not yet accepted or declined) from the current group.
   * Used to determine what groups are no longer eligible for an invitation
   */
  async loadExistingInvitations() {
    try {
      this.existingInvitationsLoaded = false;
      this._existingInvitations = [];
      const promiseArray: Promise<any>[] = [];

      // Begin getting all groups for which there could be invitations:
      const groupCollection = await this._firestoreService.col(`GroupSigningKeys`).ref.get();
      groupCollection.docs.forEach(groupDoc => {
        promiseArray.push(groupDoc.ref.get());
      });
      const promiseResults = await Promise.all(promiseArray);

      // Get all normal and invitation groups from results of promises:
      const normalAndInvitationGroups = promiseResults
        .map(groupSnapshot => groupSnapshot.data())
        .filter(group => {
          return group.groupType === environment.blockConfig.types.groups.types.normal
            || group.groupType === environment.blockConfig.types.groups.types.invitation;
        });

        // For each group public key, store an observable of their invitations in an array:
        const groupedInvitesObs = [];
        normalAndInvitationGroups.forEach(group => {
          const groupInviteCol = `ChildGroupInvitations/${group.groupPublicKey}/Invites`;
          groupedInvitesObs.push(this._firestoreService.colWithIds$(groupInviteCol));
        });

        // Create an observable of all the invitation observable arrays and subscribe to it.
        // In the subscription, create a flat array of all existing invitations from the current group:
        combineLatest(groupedInvitesObs).subscribe(allGroupedInvites => {
          this._existingInvitations = [];
          allGroupedInvites.forEach(inviteArray => {
            if (inviteArray && Array.isArray(inviteArray) && inviteArray.length > 0) {
              inviteArray.forEach(invite => {
                if (invite.data.unencryptedData.parentGroupPublicKey === this._group.groupPublicKey) {
                  this._existingInvitations.push(invite);
                }
              });
            }
          });
        });
    } catch (err) {
      await this.logToFirebase('There was a problem retrieving the existing invitations: ' + err);
      this._loggingService.logMessage('There was a problem retrieving the existing invitations.', null, 'error');
      this.existingInvitationsLoaded = false;
      return;
    }
    this.existingInvitationsLoaded = true;
  }

  /**
   * Sets up the subscription for the group search query.
   * Determines which groups match the query and displays them for the user's selection.
   */
  setGroupSearchQuery() {
    // Watch for add new group search queries
    this.groupSearchQuery
      .pipe(
        distinctUntilChanged(),
        map(q => q.toLowerCase()),
        filter(q => !!q),
        debounceTime(500)
      )
      .subscribe(query => {
        this._queryLoading.next(true);
        this.findGroupTypeAhead(query).subscribe(groups => {
          // Eligible groups consist of 1) normal and invitation groups...
          const eligibleGroups = groups.filter(group => {
            return group.groupType === environment.blockConfig.types.groups.types.normal
              || group.groupType === environment.blockConfig.types.groups.types.invitation;
          });
          for (let i = 0; i < eligibleGroups.length; i++) {
            // ...and 2) groups that aren't already selected...
            const matchedSelectedGroup = this.selectedGroups.filter(selectedGroup => {
              return selectedGroup.groupPublicKey === eligibleGroups[i].groupPublicKey;
            });
            // ...and 3) groups that aren't already shared with:
            const matchedSharedGroup = this.sharedGroupInfo.filter(sharedGroup => {
              return sharedGroup.groupPublicKey === eligibleGroups[i].groupPublicKey;
            });
            // ...and 4) groups that haven't already been invited:
            const matchedInvitation = this._existingInvitations.filter(invite => {
              return invite.data.unencryptedData.childGroupPublicKey === eligibleGroups[i].groupPublicKey;
            });
            if (matchedSelectedGroup.length > 0
                || matchedSharedGroup.length > 0
                || matchedInvitation.length > 0) {
                  eligibleGroups.splice(i, 1);
                  i--;
            }
          }

          // Display eligible groups to the user for selection:
          this.groupSearchResults = eligibleGroups;
          this._queryLoading.next(false);
        });
      }
    );
  }

  /**
   * Removes the child group passed in from sharing with the current group.
   * (Not yet implemented.)
   */
  unshareGroup(groupInfo) {
    console.log(groupInfo);
  }

  /**
   * Responds to user's key strokes in the search input.
   */
  findGroup() {
    this.query = this._queryInput.nativeElement.value;
    // hide results if input is cleared
    if (this.query.length === 0) {
      this.groupSearchResults = [];
      this.groupSearchQuery.next('');
      this._showGroupSearchResults.next(false);
      return;
    }
    this.groupSearchQuery.next(this.query);
    this._showGroupSearchResults.next(true);
  }

  /**
   * Returns an observable of groups whose name begins with the query passed in.
   *
   * @param query - the query so far entered by the user in the search input.
   */
  findGroupTypeAhead(query: string): Observable<any[]> {
    return this._firestoreService.col('GroupSigningKeys/',
      ref => ref.orderBy(`searchableIndex.${query}`)
        .limit(5)).valueChanges();
  }

  /**
   * Checks the sharedGroupInfo array to find any group matching the group public key passed in.
   * Returns true if found (meaning the group is already shared), false otherwise (meaning the group is not shared).
   *
   * @param groupPublicKey - the public key of the group to look for.
   */
  checkIfGroupIsShared(groupPublicKey: string): boolean {
    const targetGroup = this.sharedGroupInfo.filter(group => {
      return group.groupPublicKey === groupPublicKey;
    });
    return targetGroup.length > 0;
  }

  /**
   * Adds the group passed in to the selected group list
   * and removes it from the search results list at the index passed in.
   *
   * @param group - the group to add to the selected group list and remove from the search results list.
   * @param index - the index at which to remove the group from the search results list.
   */
  addToSelectedList(group: any, index: number) {
    const contains = this.selectedGroups.filter(selectedGroup =>
      (selectedGroup.groupPublicKey === group.groupPublicKey));
    if (contains.length === 0) {
      this.selectedGroups.push(group);
      this.groupSearchResults.splice(index, 1);
    }
  }

  /**
   * Removes the group passed in from the selected group list at the index passed in.
   *
   * @param group - the group to remove from the selected group list.
   * @param index - the index at which to remove the group from the selected group list.
   */
  removeFromSelectedList(group: any, index: number) {
    this.selectedGroups.splice(index, 1);
    if (group.groupName.startsWith(this._queryInput.nativeElement.value)) {
      this.groupSearchResults.push(group);
    }
  }

  /**
   * Sends an invitation to share the current group with the groups the user has selected for sharing.
   */
  async createInvitation() {
    try {
      this.isSending = true;
      if (this.selectedGroups.length !== 0) {
        const promiseArray: Promise<any>[] = [];
        this.selectedGroups.forEach(group => {
          const sendResultPromise = this._childGroupInvitationService.sendInvitation(
            this.createInvitationForm.get('invitationComment').value,
            group.groupPublicKey,
            this._group.groupPublicKey
          );
          promiseArray.push(sendResultPromise);
        });
        await Promise.all(promiseArray);
        this._loggingService.logMessage('Invitation(s) sent successfully.', false, 'success');
      } else {
        this._loggingService.logMessage('No groups selected or filled in.', false, 'error');
      }
    } catch (err) {
      await this.logToFirebase('There was a problem sending one or more invitations: ' + err);
      this._loggingService.logMessage('There was a problem sending one or more invitations.', false, 'error');
    }

    // reset stuff
    this.selectedGroups = [];
    this.createInvitationForm.reset();
    this.isSending = false;
  }

  /**
   * Determines whether the send button should be disabled or not.
   */
  shouldDisableSendButton(): boolean {
    return this.selectedGroups.length === 0
      || this.createInvitationForm.controls['invitationComment'].value.length === 0
      || !this.existingInvitationsLoaded
      || !this.sharedGroupInfoLoaded
      || this.isSending;
  }

  /**
   * Determines whether to disable the search input.
   */
  shouldDisableSearch(): boolean {
    return !this.existingInvitationsLoaded
      || !this.sharedGroupInfoLoaded;
  }

  /**
   * Uses Log service to log an error messages to firebase.
   *
   * @param message - the message to log
   */
  async logToFirebase(message: string) {
    if (this._evaGlobalService && this._evaGlobalService.userId) {
      const log = new Log(
        LogLevel.Error,
        EntityType.other,
        null,
        message,
        this._evaGlobalService.userId);
      try {
        await this._logService.error(log);
      } catch (err) {
        console.log('The following message could not be logged to firebase:', message);
      }
    } else {
      console.log('The following message could not be logged to firebase:', message);
    }
  }
}
